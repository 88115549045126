// AuthContext.js

import React, { createContext, useReducer, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// Create AuthContext
export const AuthContext = createContext();

export const useAuth = () => {
  const context = React.useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

// Initial state for the auth reducer
const initialState = {
  isAuthenticated: false,
  user: null,
  token: null,
  role: null,
  loading: true,
  isProfileComplete: null,
};

// Reducer function to manage auth state changes
const authReducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        token: action.payload.token,
        role: action.payload.role,
        loading: false,
      };
    case "LOGOUT":
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        token: null,
        role: null,
        loading: false,
      };
    case "CHECK_AUTH":
      return {
        ...state,
        isAuthenticated: !!action.payload.token,
        user: action.payload.user,
        token: action.payload.token,
        role: action.payload.role,
        loading: false,
      };
    default:
      return state;
  }
};

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    const user = JSON.parse(localStorage.getItem("user"));
    const role = localStorage.getItem("role");

    if (token && user) {
      dispatch({ type: "CHECK_AUTH", payload: { token, user, role } });
    } else {
      dispatch({ type: "CHECK_AUTH", payload: { token: null, user: null, role: null } });
    }
  }, []);

  const login = async (userData, token, firstName, lastName, phoneNumber, email, role, isProfileComplete) => {
    try {
      localStorage.setItem("token", token);
      localStorage.setItem("isProfileComplete", isProfileComplete);
      localStorage.setItem("firstName", firstName);
      localStorage.setItem("lastName", lastName);
      localStorage.setItem("phoneNumber", phoneNumber);
      localStorage.setItem("email", email);
      localStorage.setItem("role", role);
      localStorage.setItem("user", JSON.stringify(userData));
      dispatch({ type: "LOGIN", payload: { user: userData, token, role } });

      // Redirect based on role and profile completeness
      if (role === "candidate") {
        isProfileComplete ? navigate("/student/dashboard") : navigate("/student/profile");
      } else if (role === "company") {
        navigate("/company/dashboard");
      } else if (role === "admin") {
        navigate("/admin/dashboard");
      }
    } catch (error) {
      console.error("Error during login:", error);
    }
  };

  const logout = () => {
    localStorage.clear(); 
    dispatch({ type: "LOGOUT" });
    navigate("/");
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: state.isAuthenticated,
        user: state.user,
        token: state.token,
        role: state.role,
        loading: state.loading,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
