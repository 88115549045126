import { useState, useContext } from "react";
import Header from "../../components/General/Header/Header";
import ErrorModal from "../../components/General/ErrorModal/ErrorModal";
import Footer from "../../components/General/Footer/Footer";
import styled from "styled-components";
import BasicInformationForm from "../../components/StudentProfile/BasicInformationForm/BasicInformationForm";
import SkillsAndExperienceForm from "../../components/StudentProfile/SkillsAndExperienceForm/SkillsAndExperienceForm";
import RoleIndustryPreferences from "../../components/StudentProfile/RoleIndustryPreferences/RoleIndustryPreferences";
import AdditionalInformation from "../../components/StudentProfile/AdditionalInformation/AdditionalInformation";
import PersonalInformation from "../../components/StudentProfile/PersonalInformation/PersonalInformation";
import { useProfile } from "../../Contex/ProfileContext";
import { AuthContext } from "../../Contex/AuthContext";
import "./StudentProfile.css";
import ClipLoader from "react-spinners/ClipLoader";

const ProgressBar = styled.div`
  width: 100%;
  background-color: #d7e8f6;
  border-radius: 12px;
  margin-bottom: 0.5rem;
  height: 1.2rem;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;

  div {
    width: ${({ step }) => (step / 5) * 100}%;
    background: linear-gradient(90deg, #f68712, #f68712);
    height: 100%;
    transition: width 0.4s ease;
    box-shadow: 0 4px 6px rgba(0, 123, 255, 0.2);
  }
`;

export default function StudentProfile() {
  const {
    profileData,
    universities,
    graduationYears,
    degreePrograms,
    staticMajors,
    states,
    updateProfileData,
    submitProfileData,
    errorMessages,
    modalIsOpen,
    setModalIsOpen,
    isLoading,
  } = useProfile();

  const { logout } = useContext(AuthContext);
  const [step, setStep] = useState(1);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    updateProfileData({ [name]: type === "checkbox" ? checked : value });
  };

  const handleNextStep = () => setStep(step + 1);
  const handlePreviousStep = () => setStep(step - 1);
  const closeModal = () => setModalIsOpen(false);

  const centerLinks = [];
  const rightLinks = [
    {
      href: null,
      label: "Log out",
      className: "students-link",
      onClick: logout,
    },
  ];

  return (
    <div className="studentProfile">
      <Header centerLinks={centerLinks} rightLinks={rightLinks} />
      <div className="profileFormContainer">
        <ProgressBar step={step}>
          <div />
        </ProgressBar>

        <form className="profileForm" onSubmit={(e) => e.preventDefault()}>
          {step === 1 && (
            <BasicInformationForm
              profileData={profileData}
              handleChange={handleChange}
              handleNextStep={handleNextStep}
              universities={universities}
              graduationYears={graduationYears}
              degreePrograms={degreePrograms}
              staticMajors={staticMajors}
              states={states}
            />
          )}

          {step === 2 && (
            <SkillsAndExperienceForm
              profileData={profileData}
              handleChange={handleChange}
              handleNextStep={handleNextStep}
              handlePreviousStep={handlePreviousStep}
            />
          )}

          {step === 3 && (
            <RoleIndustryPreferences
              profileData={profileData}
              handleChange={handleChange}
              handleNextStep={handleNextStep}
              handlePreviousStep={handlePreviousStep}
            />
          )}

          {step === 4 && (
            <AdditionalInformation
              profileData={profileData}
              handleChange={handleChange}
              handleNextStep={handleNextStep}
              handlePreviousStep={handlePreviousStep}
            />
          )}

          {step === 5 && (
            <PersonalInformation
              profileData={profileData}
              handleChange={handleChange}
              handlePreviousStep={handlePreviousStep}
              handleSubmit={submitProfileData}
            />
          )}
        </form>

        <ErrorModal
          show={modalIsOpen}
          title={errorMessages[0]?.includes("successfully") ? "Success" : "Error"}
          errors={errorMessages}
          onClose={closeModal}
        />
      </div>

      {/* Overlay with loading spinner */}
      {isLoading && (
        <div className="loading-overlay">
          <ClipLoader size={50} color={"#123abc"} loading={isLoading} />
          <p className="loading-text">Updating profile... Please wait.</p>
        </div>
      )}

      <Footer />
    </div>
  );
}
