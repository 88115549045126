import React, { useState } from "react";
import "./FAQSection.css";

export default function FAQSection () {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  const faqData = [
    {
      question: "Is there a fee for students to use Vylero?",
      answer:
        "No, we will never charge our students. We believe everyone should have equal access to opportunities, regardless of their background. Our mission is to make meaningful internships available to all.",
    },
    {
      question: "How does your AI work in the matching process?",
      answer:
        "AI is just one component of our process. We also host info sessions and get to know each candidate personally. While AI helps in initial matching, we believe it's the personalities and values of interns that truly matter in building successful relationships.",
    },
    {
      question: "What startup industries do you support?",
      answer:
        "We welcome startups from all tech, AI, social impact, and unique industries. If you're building something cool and innovative, we're here to help you find interns who fit your needs and contribute to your vision.",
    },
    {
      question: "What roles can interns help with?",
      answer:
        "Anything! We have students ranging from undergrads to PhDs in all majors, so whether you need a software engineering intern, a biotech researcher, or a marketing specialist, we can connect you with the right talent.",
    },
    {
      question: "How long does the matching process take?",
      answer:
        "Instant! Once startups register and input their intern requirements, we immediately show you the best candidates for your position. You can send interview invites right away. Depending on your interview process, you could have interns onboard in just a few days.",
    },
    {
      question: "Are international students eligible?",
      answer:
        "Yes, international students enrolled in US-based colleges and universities are eligible to apply for internships through Vylero, provided they have the necessary work authorization (e.g., CPT, OPT).",
    },
    {
      question: "How long are the internships?",
      answer:
        "Internships typically last between 3 to 6 months, but we offer flexibility to match the needs of both students and startups. Specific durations can be customized based on the project requirements and availability.",
    },

    {
      question: "What geographic areas do you cover?",
      answer:
        "All startups using Vylero should be based in the USA, and all our students are currently enrolled in US-based colleges and universities. This ensures a smooth process and easy access for both parties.",
    },
  ];

  return (
    <div className="faq-section" id="faq">
      <h1 className="faq-title">Frequently Asked Questions</h1>
      <div className="faq-list">
        {faqData.map((faq, index) => (
          <div
            key={index}
            className={`faq-item ${activeIndex === index ? "active" : ""}`}
          >
            <div className="faq-question" onClick={() => toggleFAQ(index)}>
              <h3>{faq.question}</h3>
              <span className="toggle-icon">
                {activeIndex === index ? "-" : "+"}
              </span>
            </div>
            <div>
              <p className="faq-answer-temp">{faq.answer}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

