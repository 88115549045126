import React, { useContext } from "react";
import Header from "../../components/General/Header/Header";
import CardSection from "../../components/StudentDashboard/CardSection";
import { AuthContext } from "../../Contex/AuthContext";
import Footer from "../../components/General/Footer/Footer";

export default function StudentDashboard() {
  const { logout } = useContext(AuthContext);

  const centerLinks = [];
  const rightLinks = [
    {
      href: null,
      label: "Log out",
      className: "students-link",
      onClick: logout,
    },
  ];

  return (
    <div className="dashboard">
      <Header centerLinks={centerLinks} rightLinks={rightLinks} />
      <CardSection />
      <Footer />
    </div>
  );
}
