// import React, { useEffect } from 'react';
// import { useLocation } from 'react-router-dom';
// import './EvaluateResultPage.css';
 
// function EvaluateResultPage() {
//   const location = useLocation();
 
//   useEffect(() => {
//     console.log("EvaluateResultPage mounted");
//     console.log("Full location object:", location);
//     console.log("Candidates data:", location.state?.candidates);
//   }, [location]);
 
//   const candidates = location.state?.candidates || [];
 
//   return (
//     <div className="evaluate-result-page">
//       <h2>Candidates for your position</h2>
//       {candidates.length === 0 && <p>No candidates data received.</p>}
//       <div className="candidates-container">
//         {candidates.map(candidate => (
//           <div className="candidate-card" key={candidate.candidateId}>
//             <div className="candidate-avatar">
//               <a href={`http://localhost:5002/${candidate.resume}`} target="_blank" rel="noopener noreferrer">
//                 <img src={`http://localhost:5002/${candidate.resume}`} alt={`${candidate.result['Profile Summary']}'s resume`} />
//               </a>
//             </div>
//             <div className="candidate-info">
//               <h3>{candidate.result['Profile Summary']}</h3>
//               <p><strong>JD Match:</strong> {candidate.result['JD Match']}</p>
//               <p><strong>Skill Score:</strong> {candidate.result['Skill Score']}</p>
//               <p><strong>Matched Skills:</strong> {candidate.result['Matched Skills'].join(', ')}</p>
//               <p><strong>Missing Skills:</strong> {candidate.result['Missing Skills'].join(', ')}</p>
//               <div className="candidate-actions">
//                 <button className="view-profile-button">View Profile</button>
//                 <button className="interview-invitation-button">Send Interview Invitation</button>
//               </div>
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// }
 
// export default EvaluateResultPage;



import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './EvaluateResultPage.css';

export default function EvaluateResultPage() {
  const location = useLocation();

  useEffect(() => {
    console.log("EvaluateResultPage mounted");
    console.log("Full location object:", location);
    console.log("Candidates data:", location.state?.candidates);
  }, [location]);

  const candidates = location.state?.candidates || [];

  return (
    <div className="evaluate-result-page">
      <h2>Candidates for your position</h2>
      {candidates.length === 0 && <p>No candidates data received.</p>}
      <div className="candidates-container">
        {candidates.map(candidate => (
          <div className="candidate-card" key={candidate.candidateId}>
            <div className="candidate-avatar">
              <a href={`${process.env.REACT_APP_API_BASE_URL}/${candidate.resume}`} target="_blank" rel="noopener noreferrer">
                <img src={`${process.env.REACT_APP_API_BASE_URL}/${candidate.resume}`} alt={`${candidate.result['Profile Summary']}'s resume`} />
              </a>
            </div>
            <div className="candidate-info">
              <h3>{candidate.result['Profile Summary']}</h3>
              <p><strong>JD Match:</strong> {candidate.result['JD Match']}</p>
              <p><strong>Skill Score:</strong> {candidate.result['Skill Score']}</p>
              <p>
                <strong>Matched Skills:</strong>
                {candidate.result['Matched Skills'] ? candidate.result['Matched Skills'].join(', ') : 'N/A'}
              </p>
              <p>
                <strong>Missing Skills:</strong>
                {candidate.result['Missing Skills'] ? candidate.result['Missing Skills'].join(', ') : 'N/A'}
              </p>
              <div className="candidate-actions">
                <button className="view-profile-button">View Profile</button>
                <button className="interview-invitation-button">Send Interview Invitation</button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

