// App.js

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage/HomePage';
import LoginPage from './pages/LoginPage/LoginPage';
import FormPage from './pages/FormPage/FormPage';
import SignUpPage from './pages/SignUpPage/SignUpPage';
import EvaluateResultPage from './pages/EvaluateResultPage/EvaluateResultPage';
import CandidatePage from './pages/CandiateTablePage/CandidatePage';
import StudentEmailVerificationPage from './pages/StudentEmailVerification/StudentEmailVerificationPage';
import StudentVerificationPage from './pages/StudentVerification/StudentVerificationPage';
import StudentLandingPage from './pages/StudentLandingPage/StudentLandingPage';
import StudentLogIn from './pages/StudentLogIn/StudentLogIn';
import StudentSignUp from './pages/StudentSignUp/StudentSignUp';
import StudentDashboard from './pages/StudentDashboard/StudentDashboard';
import StudentProfile from './pages/StudentProfile/StudentProfile';
import CompanyLogIn from './pages/CompanyLogIn/CompanyLogIn';
import CompanySignUp from './pages/CompanySignUp/CompanySignUp';
// import CompanyDashboard from './pages/CompanyDashboard/CompanyDashboard';
import { ProfileProvider } from './Contex/ProfileContext';
import { AuthProvider } from './Contex/AuthContext';
import ProtectedRoute from './Contex/ProtectedRoute'
import CompanyProfile from './pages/CompanyProfile/CompanyProfile';

export default function App() {
  return (
    <Router>
      <AuthProvider>
        <ProfileProvider>
          <Routes>
            {/* Public Routes */}
            <Route path="/" element={<HomePage />} />
            <Route path="/new/student/login" element={<StudentLogIn />} />
            <Route path="/new/student/signUp" element={<StudentSignUp />} />
            <Route path="/company/login" element={<CompanyLogIn />} />
            <Route path="/company/signUp" element={<CompanySignUp />} />
            <Route path="/company/profile" element={<CompanyProfile />} />

            {/* Public Routes for specific pages */}
            <Route path="/student/landing" element={<StudentLandingPage />} />
            <Route path="/student/signup/verification" element={<StudentEmailVerificationPage />} />
            <Route path="/student/signup/verification2" element={<StudentVerificationPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/form" element={<FormPage />} />
            <Route path="/signup" element={<SignUpPage />} />
            <Route path="/evaluate-result" element={<EvaluateResultPage />} />
            <Route path="/candidate" element={<CandidatePage />} />

            {/* Protected Routes for Candidates */}
            <Route
              path="/student/dashboard"
              element={
                <ProtectedRoute allowedRoles={['candidate']}>
                  <StudentDashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="/student/profile"
              element={
                <ProtectedRoute allowedRoles={['candidate']}>
                  <StudentProfile />
                </ProtectedRoute>
              }
            />

            {/* Protected Routes for Companies */}
            {/* <Route
              path="/company/dashboard"
              element={
                <ProtectedRoute allowedRoles={['company']}>
                  <CompanyDashboard />
                </ProtectedRoute>
              }
            /> */}
          </Routes>
        </ProfileProvider>
      </AuthProvider>
    </Router>
  );
}
