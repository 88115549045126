// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.feedback-section {
  padding: 100px 50px;
  text-align: center;
  background-color: #f0f5ff;
}

.feedback-heading {
  font-size: 48px;
  margin-bottom: 50px;
  color: #2a2a2a;
  opacity: 1;
  transition: opacity 0.5s ease;
}

.feedback-card-container {
  display: flex;
  justify-content: space-around;
  gap: 30px;
  flex-wrap: wrap;
  margin-top: 30px;
}

.feedback-card {
  background-color: #b3c5ff;
  padding: 30px;
  border-radius: 20px;
  max-width: 350px;
  min-width: 300px;
  opacity: 0;
  transform: translateY(100px);
  transition: opacity 0.3s ease, transform 0.3s ease;
  text-align: center;
  color: #2a2a2a;
}

.feedback-card:hover {
  cursor: pointer;
}

.feedback-card p {
  font-size: 18px;
  line-height: 1.6;
  color: #1e2b60;
}

.company-logo {
  width: 100px;
  height: auto;
  margin-bottom: 15px;
}

.feedback-reviewer-name {
  font-weight: bold;
  margin-top: 10px;
  font-size: 16px;
  color: #1e2b60;
}

@media (max-width: 768px) {
  .feedback-card-container {
    flex-direction: column;
    align-items: center;
  }

  .feedback-card {
    max-width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/StudentLandingPage/StudentReview/StudentReview.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,kBAAkB;EAClB,yBAAyB;AAC3B;;AAEA;EACE,eAAe;EACf,mBAAmB;EACnB,cAAc;EACd,UAAU;EACV,6BAA6B;AAC/B;;AAEA;EACE,aAAa;EACb,6BAA6B;EAC7B,SAAS;EACT,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;EACzB,aAAa;EACb,mBAAmB;EACnB,gBAAgB;EAChB,gBAAgB;EAChB,UAAU;EACV,4BAA4B;EAC5B,kDAAkD;EAClD,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,eAAe;EACf,cAAc;AAChB;;AAEA;EACE;IACE,sBAAsB;IACtB,mBAAmB;EACrB;;EAEA;IACE,eAAe;EACjB;AACF","sourcesContent":[".feedback-section {\n  padding: 100px 50px;\n  text-align: center;\n  background-color: #f0f5ff;\n}\n\n.feedback-heading {\n  font-size: 48px;\n  margin-bottom: 50px;\n  color: #2a2a2a;\n  opacity: 1;\n  transition: opacity 0.5s ease;\n}\n\n.feedback-card-container {\n  display: flex;\n  justify-content: space-around;\n  gap: 30px;\n  flex-wrap: wrap;\n  margin-top: 30px;\n}\n\n.feedback-card {\n  background-color: #b3c5ff;\n  padding: 30px;\n  border-radius: 20px;\n  max-width: 350px;\n  min-width: 300px;\n  opacity: 0;\n  transform: translateY(100px);\n  transition: opacity 0.3s ease, transform 0.3s ease;\n  text-align: center;\n  color: #2a2a2a;\n}\n\n.feedback-card:hover {\n  cursor: pointer;\n}\n\n.feedback-card p {\n  font-size: 18px;\n  line-height: 1.6;\n  color: #1e2b60;\n}\n\n.company-logo {\n  width: 100px;\n  height: auto;\n  margin-bottom: 15px;\n}\n\n.feedback-reviewer-name {\n  font-weight: bold;\n  margin-top: 10px;\n  font-size: 16px;\n  color: #1e2b60;\n}\n\n@media (max-width: 768px) {\n  .feedback-card-container {\n    flex-direction: column;\n    align-items: center;\n  }\n\n  .feedback-card {\n    max-width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
