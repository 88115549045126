import React, { useEffect } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "./Review.css";
import verunaLogo from "../../../assets/review/1.png";
import plainsightLogo from "../../../assets/review/2.png";
import cleanaLogo from "../../../assets/review/3.png";

gsap.registerPlugin(ScrollTrigger);

export default function Review () {
  useEffect(() => {
    const heading = document.querySelector(".review-heading");
    const reviewCards = document.querySelectorAll(".review-card");

    const mm = gsap.matchMedia();

    mm.add(
      {
        isDesktop: "(min-width: 541px)",
      },
      () => {
        gsap.set(reviewCards, {
          opacity: 0,
          y: 100,
        });

        gsap.set(heading, {
          opacity: 1,
        });
        gsap
          .timeline({
            scrollTrigger: {
              trigger: heading,
              start: "top center",
              end: "bottom center",
              scrub: true,
            },
          })
          .to(heading, {
            opacity: 0,
            duration: 1,
          });

        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: reviewCards[0],
            start: "top 90%",
            end: "+=400",
            scrub: true,
          },
        });

        reviewCards.forEach((card, index) => {
          tl.to(
            card,
            {
              opacity: 1,
              y: 0,
              duration: 2,
              ease: "power2.out",
            },
            `+=${index * 0.5}`
          );
        });
      }
    );

    return () => {
      mm.revert();
    };
  }, []);

  return (
    <section className="review-section">
      <h2 className="review-heading">What Our Clients Say</h2>
      <div className="review-card-container">
        <div className="review-card">
          <img src={verunaLogo} alt="Veruna Logo" className="company-logo" />
          <p>
            Vylero connected us with skilled interns who quickly became key
            contributors to our projects. The process was fast and efficient!
          </p>
          <p className="reviewer-name">Kate, HR</p>
        </div>

        <div className="review-card">
          <img
            src={plainsightLogo}
            alt="Plainsight Logo"
            className="company-logo"
          />
          <p>
            Vylero didn’t just find us interns - they brought us future leaders.
            Some are already stepping into management roles.
          </p>
          <p className="reviewer-name">Andrew, CTO</p>
        </div>

        <div className="review-card">
          <img src={cleanaLogo} alt="Cleana Logo" className="company-logo" />
          <p>
            We avoided interns because it felt like too much work. Vylero
            changed that overnight - now they’re driving our success.
          </p>
          <p className="reviewer-name">Kevin, CEO</p>
        </div>
      </div>
    </section>
  );
};

