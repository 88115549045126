import React, { useEffect } from "react";
import "./CustomCard.css";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default function CustomCard  ({
  image,
  content,
  buttonTitle1,
  onButtonClick1,
  buttonTitle2,
  onButtonClick2,
  flexDirection = "row",
})  {
  useEffect(() => {
    const cards = document.querySelectorAll(".student-card");

    cards.forEach((card) => {
      gsap.fromTo(
        card,
        {
          opacity: 0,
          y: 50,
          scale: 0.95,
        },
        {
          opacity: 1,
          y: 0,
          scale: 1,
          scrollTrigger: {
            trigger: card,
            start: "top 80%",
            toggleActions: "play none none reverse",
          },
          duration: 1,
          ease: "power3.out",
        }
      );
    });
  }, []);

  return (
    <div
      className={`student-card ${
        flexDirection === "row-reverse" ? "reverse" : ""
      }`}
    >
      <div className="card-image">
        <img src={image} alt="Card Visual" />
      </div>

      <div className="card-content">
        <h2>{content.title}</h2>
        <p>{content.description}</p>

        {buttonTitle1 && onButtonClick1 && (
          <button className="card-button" onClick={onButtonClick1}>
            {buttonTitle1}
          </button>
        )}

        {buttonTitle2 && onButtonClick2 && (
          <button className="card-button" onClick={onButtonClick2}>
            {buttonTitle2}
          </button>
        )}
      </div>
    </div>
  );
};

