import React, { useState, useEffect, useContext } from "react";
import "./CompanySignUp.css";
import logo from "../../assets/logo/small_transparent_logo.png";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ErrorModal from "../../components/General/ErrorModal/ErrorModal";
import Header from "../../components/General/Header/Header";
import { AuthContext } from "../../Contex/AuthContext";
import ClipLoader from "react-spinners/ClipLoader";
import industryData from "../../utils/CompanyProfileData/industryData.json"; // Placeholder for industry list
import locationData from "../../utils/CompanyProfileData/locationData.json"; // Placeholder for location list
import Select from "react-select"; // Import react-select for dropdown

export default function CompanySignUp() {
  const { isAuthenticated } = useContext(AuthContext);
  const [formData, setFormData] = useState({
    companyName: "",
    personFullName: "",
    email: "",
    password: "",
    confirmPassword: "",
    companyWebsite: "",
    industry: "",
    shortDescription: "",
    location: "",
  });
  const [step, setStep] = useState(1);
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const centerLinks = [];

  const rightLinks = [
    {
      href: "/company/login",
      label: "Log In",
      className: "companies-link",
    },
    {
      href: "/company/signUp",
      label: "Sign Up",
      className: "students-link",
    },
  ];

  useEffect(() => {
    const isProfileComplete =
      localStorage.getItem("isProfileComplete") === "true";

    if (localStorage.getItem("token") && isAuthenticated) {
      if (!isProfileComplete) {
        navigate("/company/profile");
      } else {
        navigate("/company/dashboard");
      }
    }
  }, [isAuthenticated, navigate]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSelectChange = (selectedOption, name) => {
    setFormData({ ...formData, [name]: selectedOption });
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const validateWebsite = (url) => {
    const websitePattern = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]{2,}\.)+[a-zA-Z]{2,}(\/.*)?$/;
    const linkedInPattern = /^(https?:\/\/)?(www\.)?linkedin\.com\/.*$/;
    return websitePattern.test(url) || linkedInPattern.test(url);
  };

  const validateStepOne = () => {
    let tempErrors = [];
    if (!formData.companyName) tempErrors.push("Company name is required");
    if (!formData.personFullName) tempErrors.push("Full name is required");
    if (!formData.email) {
      tempErrors.push("Email is required");
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      tempErrors.push("Email is not valid");
    }

    if (!formData.password) {
      tempErrors.push("Password is required");
    } else if (formData.password.length < 12) {
      tempErrors.push("Password must be at least 12 characters long");
    } else if (
      !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
        formData.password
      )
    ) {
      tempErrors.push(
        "Password must contain at least 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character"
      );
    }

    if (!formData.confirmPassword) {
      tempErrors.push("Confirm password is required");
    } else if (formData.password !== formData.confirmPassword) {
      tempErrors.push("Passwords do not match");
    }

    setErrors(tempErrors);
    return tempErrors.length === 0;
  };

  const validateStepTwo = () => {
    let tempErrors = [];
    if (!formData.companyWebsite)
      tempErrors.push("Company website or LinkedIn page is required");
    else if (!validateWebsite(formData.companyWebsite))
      tempErrors.push(
        "Company website or LinkedIn link must be a valid URL."
      );

    if (!formData.industry) tempErrors.push("Industry is required");
    if (!formData.location) tempErrors.push("Location is required");
    if (!formData.shortDescription)
      tempErrors.push("Short description is required");

    setErrors(tempErrors);
    return tempErrors.length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (step === 1 && !validateStepOne()) {
      setShowModal(true);
      return;
    }
    if (step === 2 && !validateStepTwo()) {
      setShowModal(true);
      return;
    }

    if (step === 1) {
      setStep(2);
      return;
    }

    setLoading(true);
    setErrors([]);
    setMessage("");

    try {
      const {
        companyName,
        personFullName,
        email,
        password,
        companyWebsite,
        industry,
        shortDescription,
        location,
      } = formData;

      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/companies/auth/register`,
        {
          companyName,
          personFullName,
          email,
          password,
          companyWebsite,
          industry: industry.value,
          shortDescription,
          location: location.value, 
        }
      );

      const successMessage =
        response.data.message || "Registration successful.";
      setMessage(successMessage);
      setErrors([successMessage]);
      setShowModal(true);

      setTimeout(() => {
        navigate("/company/login");
      }, 2000);
    } catch (error) {
      setErrors([
        error.response?.data?.message ||
          "An error occurred during registration.",
      ]);
      setShowModal(true);
    } finally {
      setLoading(false);
    }
  };

  const customSelectStyles = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: "8px",
      padding: "0px 3px",
      boxShadow: "none",
      boxShadow: 'none',
      '&:hover': {
        borderColor: '#132540',
      },
      borderColor: state.isFocused ? "#132540" : "#d7e8f6",
    }),
    input: (provided) => ({
      ...provided,
      padding: "0px 10px",
      fontSize: "14px",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#b9d2ec"
        : state.isFocused
        ? "#dfeaf5"
        : "white",
      color: state.isSelected ? "white" : "#132540",
      padding: "6px 15px",
      cursor: "pointer",
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: "#f7e8d6",
      borderRadius: "8px",
      padding: "2px 5px",
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: "#132540",
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: "#f68712",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#f68712",
        color: "white",
      },
    }),
  };

  return (
    <div className="signup-body ">
      <Header centerLinks={centerLinks} rightLinks={rightLinks} />
      <div className="signup-page">
        <div className={`login-content`}>
          <div className="login-left">
            <img src={logo} alt="Vylero Logo" className="login-logo" />
            <h2>Create Your Company Account</h2>
            <p>Get started and connect with the best talent in the industry.</p>
          </div>

          <div className="login-right">
            <form onSubmit={handleSubmit} className="login-form">
              {step === 1 && (
                <>
                  <div className="login-form-group">
                    <label>Company Name</label>
                    <input
                      type="text"
                      name="companyName"
                      placeholder="Enter your company name"
                      value={formData.companyName}
                      onChange={handleChange}
                      className="name-class"
                      required
                    />
                  </div>

                  <div className="login-form-group">
                    <label>Full Name</label>
                    <input
                      type="text"
                      name="personFullName"
                      placeholder="Enter your full name"
                      value={formData.personFullName}
                      onChange={handleChange}
                      className="name-class"
                      required
                    />
                  </div>

                  <div className="login-form-group">
                    <label>Email</label>
                    <input
                      type="email"
                      name="email"
                      placeholder="Enter your email address"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <div className="login-form-group">
                    <label>Password</label>
                    <input
                      type="password"
                      name="password"
                      placeholder="Create a password"
                      value={formData.password}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <div className="login-form-group">
                    <label>Confirm Password</label>
                    <input
                      type="password"
                      name="confirmPassword"
                      placeholder="Confirm your password"
                      value={formData.confirmPassword}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <button type="submit" className="btn-login">
                    Next
                  </button>
                </>
              )}

              {step === 2 && (
                <>
                  <div className="login-form-group">
                    <label>Company Website</label>
                    <input
                      type="url"
                      name="companyWebsite"
                      placeholder="Enter your website or LinkedIn profile URL"
                      value={formData.companyWebsite}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <div className="login-form-group">
                    <label>Industry</label>
                    <Select
                      name="industry"
                      value={formData.industry}
                      options={industryData.map((industry) => ({
                        value: industry.value,
                        label: industry.label,
                      }))}
                      onChange={(selectedOption) =>
                        handleSelectChange(selectedOption, "industry")
                      }
                      placeholder="Select an industry"
                      styles={customSelectStyles} // Apply custom styles here
                      required
                    />
                  </div>

                  <div className="login-form-group">
                    <label>Location Headquarters</label>
                    <Select
                      name="location"
                      value={formData.location}
                      options={locationData.map((location) => ({
                        value: location.value,
                        label: location.label,
                      }))}
                      onChange={(selectedOption) =>
                        handleSelectChange(selectedOption, "location")
                      }
                      placeholder="Select a location"
                      styles={customSelectStyles} // Apply custom styles here
                      required
                    />
                  </div>

                  <div className="login-form-group">
                    <label>Short Description</label>
                    <textarea
                      name="shortDescription"
                      placeholder="Provide a brief description of your company"
                      value={formData.shortDescription}
                      onChange={handleChange}
                      rows={4}
                      required
                    />
                  </div>

                  <div className="button-group">
                    <button
                      type="button"
                      className="btn-back"
                      onClick={() => setStep(1)}
                    >
                      Back
                    </button>
                    <button type="submit" className="btn-login">
                      Sign Up
                    </button>
                  </div>
                </>
              )}
              <p className="signup-text">
                Already have an account? <a href="/new/student/login">Log In</a>
              </p>
            </form>
          </div>
        </div>
      </div>

      {loading && (
        <div className="loading-overlay">
          <ClipLoader size={50} color={"#123abc"} loading={loading} />
          <p className="loading-text">Signing up... Please wait.</p>
        </div>
      )}

      <ErrorModal
        show={showModal}
        title={
          errors.length > 0 && errors[0].includes("successful")
            ? "Success"
            : "Error"
        }
        errors={errors}
        onClose={handleCloseModal}
      />
    </div>
  );
}
