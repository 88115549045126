import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Header from "../../components/General/Header/navigateBackHeader";
import Footer from "../../components/General/Footer/Footer";
import "bootstrap/dist/css/bootstrap.min.css";
import signupImage from "../../assets/images/svgsignupImage.svg";
import "./StudentVerificationPage.css";

export default function StudentVerificationPage() {
  const [errors, setErrors] = useState({});
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const validate = () => {
    let tempErrors = {};
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validate()) {
      console.log("OTP is valid, proceeding...");
      // Handle OTP verification and redirection
    } else {
      setShowModal(true);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleTakeMeHome = () => {
    navigate('/'); 
  };

  return (
    <div className="verification-page-body">
      <Header />
      <div className="container-fluid verification-page p-0">
        <div className="row no-gutters min-vh-100">
          <div className="col-md-7 col-12 form-section d-flex flex-column justify-content-center">
            <div className="custom-form-container">
              <p className="email-text">Email</p>
              <h2>Please Verify your Email</h2>
              <p className="email-message-text">We sent an email to deep@gmail.com</p>
              <div className="button-container">
                <Button variant="outline-dark" onClick={handleGoBack} className="mr-2">
                  <FaArrowLeft /> Go Back
                </Button>
                <Button variant="primary" onClick={handleTakeMeHome}>Take me Home</Button>
              </div>
            </div>
          </div>
          <div className="col-md-5 d-none d-md-block image-section p-0">
            <img src={signupImage} alt="Signup Background" className="img-fluid" />
          </div>
        </div>
      </div>

      {/* Modal for displaying errors */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul>
            {Object.values(errors).map((error, index) => (
              <li key={index} className="text-danger">{error}</li>
            ))}
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>Close</Button>
        </Modal.Footer>
      </Modal>

      <Footer />
    </div>
  );
}

