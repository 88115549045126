// ProtectedRoute.js

import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../Contex/AuthContext";

const ProtectedRoute = ({ children, allowedRoles }) => {
  const { isAuthenticated, role } = useAuth();

  if (!isAuthenticated) {
    // If not authenticated, redirect to login page
    return <Navigate to="/new/student/login" />;
  }

  // Check if the user's role is allowed to access the route
  if (role && allowedRoles && !allowedRoles.includes(role)) {
    // Redirect based on role if not allowed
    if (role === "candidate") {
      return <Navigate to="/student/dashboard" />;
    } else if (role === "company") {
      return <Navigate to="/company/dashboard" />;
    } else if (role === "admin") {
      return <Navigate to="/admin/dashboard" />;
    }
  }

  // Render the protected content if the role is allowed
  return children;
};

export default ProtectedRoute;
