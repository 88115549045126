// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-modal .modal-header {
    background-color: #f68712;
    color: white;
  }
  
  .error-modal .modal-title {
    font-size: 18px;
    font-weight: bold;
  }
  
  .error-modal .modal-body {
    padding: 20px;
    color: #172042;
  }
  
  .error-modal .modal-body ul {
    padding-left: 20px;
    list-style-type: disc;
  }
  
  .error-modal .modal-body li {
    color: red;
    font-size: 14px;
    margin-bottom: 8px;
  }
  
  .error-modal .modal-footer {
    display: flex;
    justify-content: flex-end;
  }
  
  .error-modal .btn-secondary {
    background-color: #132540;
    border: none;
  }
  
  .error-modal .btn-secondary:hover {
    background-color: #f68712;
    color: white;
  }
  
  .error-modal .modal-content {
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    border: none;
  }
  
  .error-modal .modal-header .close {
    color: white;
    opacity: 1;
  }
  
  .error-modal .modal-header .close:hover {
    color: #fff;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/General/ErrorModal/ErrorModal.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,YAAY;EACd;;EAEA;IACE,eAAe;IACf,iBAAiB;EACnB;;EAEA;IACE,aAAa;IACb,cAAc;EAChB;;EAEA;IACE,kBAAkB;IAClB,qBAAqB;EACvB;;EAEA;IACE,UAAU;IACV,eAAe;IACf,kBAAkB;EACpB;;EAEA;IACE,aAAa;IACb,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;IACzB,YAAY;EACd;;EAEA;IACE,yBAAyB;IACzB,YAAY;EACd;;EAEA;IACE,mBAAmB;IACnB,yCAAyC;IACzC,YAAY;EACd;;EAEA;IACE,YAAY;IACZ,UAAU;EACZ;;EAEA;IACE,WAAW;EACb","sourcesContent":[".error-modal .modal-header {\n    background-color: #f68712;\n    color: white;\n  }\n  \n  .error-modal .modal-title {\n    font-size: 18px;\n    font-weight: bold;\n  }\n  \n  .error-modal .modal-body {\n    padding: 20px;\n    color: #172042;\n  }\n  \n  .error-modal .modal-body ul {\n    padding-left: 20px;\n    list-style-type: disc;\n  }\n  \n  .error-modal .modal-body li {\n    color: red;\n    font-size: 14px;\n    margin-bottom: 8px;\n  }\n  \n  .error-modal .modal-footer {\n    display: flex;\n    justify-content: flex-end;\n  }\n  \n  .error-modal .btn-secondary {\n    background-color: #132540;\n    border: none;\n  }\n  \n  .error-modal .btn-secondary:hover {\n    background-color: #f68712;\n    color: white;\n  }\n  \n  .error-modal .modal-content {\n    border-radius: 12px;\n    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);\n    border: none;\n  }\n  \n  .error-modal .modal-header .close {\n    color: white;\n    opacity: 1;\n  }\n  \n  .error-modal .modal-header .close:hover {\n    color: #fff;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
