import React from "react";
import { useNavigate } from "react-router-dom";
import "./StudentHeroSection.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserGraduate, faSchool } from "@fortawesome/free-solid-svg-icons";

export default function StudentHeroSection() {
  const navigate = useNavigate();

  const handleStudentClick = () => {
    navigate("/new/student/login");
  };

  return (
    <section className="student-hero-tagline">
      <div className="student-hero-main-content">
        <div className="student-hero-content">
          <div className="student-hero-content-left">
            <div className="student-hero-content-left-labels">
              {/* <div className="student-hero-label">
                <FontAwesomeIcon icon={faBriefcase} className="student-hero-icon startups-icon" />
                Startups
              </div> */}
              <div className="student-hero-label">
                <FontAwesomeIcon icon={faUserGraduate} className="student-hero-icon students-icon" />
                Students
              </div>
              <div className="student-hero-label">
                <FontAwesomeIcon icon={faSchool} className="student-hero-icon schools-icon" />
                Schools & Universities
              </div>
            </div>

            <h1 className="student-hero-title">
              Connect with Startups That Value Your Talent
            </h1>
            <p className="student-hero-subtitle">
              Forget endless applications and uncertain outcomes. Create a profile, showcase your skills, and get direct interview invites from startups that are actively looking for someone like you.
            </p>

            <div className="student-hero-buttons">
              <button className="student-btn" onClick={handleStudentClick}>
              Get Started <span className="student-hero-arrow">→</span>
              </button>
              {/* <button className="get-started-btn">
                Get Started
              </button> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
