import React, { useState } from "react";
import "./Footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import logo from "../../../assets/logo/transparent_light_logo.png";

export default function Footer() {

  const [email, setEmail] = useState("");

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubscribe = (e) => {
    e.preventDefault();
    alert(`Subscribed with ${email}`);
    setEmail("");
  };

  return (
    <footer className="footer">
      <div className="footer-container">
        {/* Left Section: Logo & Description */}
        <div className="footer-brand">
          <img src={logo} alt="Folio Logo" className="footer-logo" />
          <p className="footer-text">
            Empowering Careers and Driving Social Impact Through AI-Powered
            Connections.
          </p>
        </div>

        {/* Center Section: Subscription Form */}
        <div className="footer-subscription">
          <h3>Stay Updated</h3>
          <form className="subscription-form" onSubmit={handleSubscribe}>
            <div className="input-container">
              <input
                type="email"
                value={email}
                onChange={handleEmailChange}
                placeholder="Enter your email"
                className="email-input"
                required
              />
              <button type="submit" className="subscribe-button">
                Subscribe
              </button>
            </div>
          </form>
        </div>

        {/* Right Section: Contact Info & Social Icons */}
        <div className="footer-contact">
          <h3>Contact Us</h3>
          <div className="social-icons">
            <a href="mailto:support@vylero.com">
              <FontAwesomeIcon icon={faEnvelope} />
            </a>
            <a
              href="https://www.linkedin.com/company/vylero/"

              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faLinkedin} />
            </a>
          </div>
        </div>
      </div>

      {/* Horizontal Line Before Copyright */}
      <hr className="footer-line" />

      <div className="footer-bottom">
        <p>© 2024 Vylero. All rights reserved.</p>
      </div>
    </footer>
  );
}

