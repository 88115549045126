import React, { useState } from 'react';
import { motion } from 'framer-motion';
import './SignUpPage.css';
import Header from '../../components/General/Header/navigateBackHeader';

export default function SignupPage() {
  const [formValues, setFormValues] = useState({
    fullName: '',
    companyName: '',
    email: '',
    password: ''
  });
  const [errors, setErrors] = useState({});

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const validate = () => {
    let tempErrors = {};
    if (!formValues.fullName) tempErrors.fullName = 'Full name is required';
    if (!formValues.companyName) tempErrors.companyName = 'Company name is required';
    if (!formValues.email) {
      tempErrors.email = 'Email is required';
    } else {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(formValues.email)) {
        tempErrors.email = 'Email is not valid';
      }
    }
    if (!formValues.password) {
      tempErrors.password = 'Password is required';
    } else {
      const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
      if (!passwordRegex.test(formValues.password)) {
        tempErrors.password = 'Password must contain at least 8 characters, 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character';
      }
    }

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validate()) {
      // Logic to submit form data to the backend
      console.log('Form is valid, submitting data...');
    }
  };

  return (
    <div>
      <Header />
      <div className="signup-page">
        <motion.div 
          className="signup-container"
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <motion.div 
            className="signup-form"
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
          >
            <h2>Sign up</h2>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label>Full Name*</label>
                <input 
                  type="text" 
                  name="fullName" 
                  placeholder="Enter your name" 
                  value={formValues.fullName} 
                  onChange={handleChange} 
                />
                {errors.fullName && <p className="error">{errors.fullName}</p>}
              </div>
              <div className="form-group">
                <label>Company Name*</label>
                <input 
                  type="text" 
                  name="companyName" 
                  placeholder="Enter your company name" 
                  value={formValues.companyName} 
                  onChange={handleChange} 
                />
                {errors.companyName && <p className="error">{errors.companyName}</p>}
              </div>
              <div className="form-group">
                <label>Email*</label>
                <input 
                  type="email" 
                  name="email" 
                  placeholder="Enter your email" 
                  value={formValues.email} 
                  onChange={handleChange} 
                />
                {errors.email && <p className="error">{errors.email}</p>}
              </div>
              <div className="form-group">
                <label>Password*</label>
                <input 
                  type="password" 
                  name="password" 
                  placeholder="Create a password" 
                  value={formValues.password} 
                  onChange={handleChange} 
                />
                {errors.password && <p className="error">{errors.password}</p>}
                <small>Must be at least 8 characters, contain 1 uppercase, 1 lowercase, 1 number, and 1 special character.</small>
              </div>
              <button type="submit" className="create-account-button">Create account</button>
              <button type="button" className="google-signup-button">
                <img src="https://developers.google.com/identity/images/g-logo.png" alt="Google Logo" /> Sign up with Google
              </button>
              <p className="login-link">
                Already have an account? <a href="/login">Log in</a>
              </p>
            </form>
          </motion.div>
        </motion.div>
      </div>
    </div>
  );
}

