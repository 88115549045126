import React from "react";
import "./IntroductionPoster.css";

export default function IntroductionPoster() {
  return (
    <section className="intro-section">
      <div className="intro-container">
        <h1 className="intro-title">
          Discover Opportunities that Align with Your Goals
        </h1>
        <p className="intro-description">
          We know how challenging it can be to find the right internship. That’s
          why we’ve created a platform that simplifies the process and connects
          you directly with startups that are interested in your unique skills
          and aspirations. No more wondering if your application got lost in the
          crowd—if a startup wants to interview you, you’ll know.
        </p>
      </div>
    </section>
  );
}
