import React, { useEffect, useRef } from "react";
import "./Expectation.css";
import { FaBriefcase, FaUserTie, FaClock, FaChartLine } from "react-icons/fa";

export default function ExpectatioN () {

  const sectionRef = useRef(null);

  useEffect(() => {
    const rows = sectionRef.current.querySelectorAll(".expect-row");

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("in-view");
          }
        });
      },
      {
        threshold: 0.2,
      }
    );

    rows.forEach((row) => observer.observe(row));

    return () => {
      rows.forEach((row) => observer.unobserve(row));
    };
  }, []);

  return (
    <section className="expect-section" ref={sectionRef}>
      <h2 className="expect-heading">What You Can Expect</h2>
      <div className="expect-content">
        <div className="expect-row">
          <FaBriefcase className="expect-icon" />
          <div className="expect-text">
            <h3>Real-World Experience</h3>
            <p>Work on meaningful projects that have a real impact.</p>
          </div>
        </div>

        <div className="expect-row">
          <FaUserTie className="expect-icon" />
          <div className="expect-text">
            <h3>Mentorship Opportunities</h3>
            <p>Learn from startup founders and industry professionals.</p>
          </div>
        </div>

        <div className="expect-row">
          <FaClock className="expect-icon" />
          <div className="expect-text">
            <h3>Flexible Roles</h3>
            <p>
              Find internships that fit your schedule-remote, in-person,
              part-time, or full-time.
            </p>
          </div>
        </div>

        <div className="expect-row">
          <FaChartLine className="expect-icon" />
          <div className="expect-text">
            <h3>Career Growth</h3>
            <p>
              Gain skills, make connections, and build your resume with
              experiences that stand out.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
