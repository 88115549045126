import React, { useEffect, useRef } from "react";
import "./IndustriesSection.css";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import { FaLaptopCode, FaHeartbeat, FaChartLine, FaPaintBrush, FaBullhorn, FaHandsHelping } from "react-icons/fa";

gsap.registerPlugin(ScrollTrigger);

export default function IndustriesSection () {
  const sectionRef = useRef(null);

  useEffect(() => {
    const industries = sectionRef.current.querySelectorAll(".industry-item");

    industries.forEach((industry, index) => {
      gsap.fromTo(
        industry,
        { opacity: 0, y: 50 },
        {
          opacity: 1,
          y: 0,
          delay: index * 0.2,
          duration: 0.6,
          ease: "power3.out",
          scrollTrigger: {
            trigger: industry,
            start: "top 85%", 
          },
        }
      );
    });
  }, []);

  return (
    <section className="industries-section" ref={sectionRef}>
      <h2 className="industries-heading">Industries We Serve</h2>
      <ul className="industries-list">
        <li className="industry-item">
          <FaLaptopCode className="industry-icon" /> Technology & Engineering
        </li>
        <li className="industry-item">
          <FaHeartbeat className="industry-icon" /> Marketing & Communications
        </li>
        <li className="industry-item">
          <FaChartLine className="industry-icon" /> Finance & Fintech
        </li>
        <li className="industry-item">
          <FaPaintBrush className="industry-icon" /> Creative & Design
        </li>
        <li className="industry-item">
          <FaBullhorn className="industry-icon" /> Healthcare & Biotech
        </li>
        <li className="industry-item">
          <FaHandsHelping className="industry-icon" /> Social Impact & Nonprofits
        </li>
      </ul>
      {/* <p className="industries-note">
        No matter your field of study, we have opportunities that will help you
        grow and achieve your career goals.
      </p> */}
    </section>
  );
};

