import React from "react";
import "./Reason.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faLock,
  faLightbulb,
  faRocket,
} from "@fortawesome/free-solid-svg-icons";

export default function Reason () {
  return (
    <section className="journey-section" id="about">
      <div className="journey-content">
        <h1>Opportunities, Genuine and Aligned</h1>
      </div>
      <div className="journey-cards">
        <div className="journey-card">
          <FontAwesomeIcon icon={faLightbulb} className="card-icon" />
          <h3>Invitation-Only Interviews</h3>
          <p>
            Students skip the job hunt - receive interview invites from startups
            that are actively interested in your profile.
          </p>
        </div>
        <div className="journey-card">
          <FontAwesomeIcon icon={faCheckCircle} className="card-icon" />
          <h3>Pre - Qualified Talent</h3>
          <p>
            Startups access a pool of pre-vetted, high-quality candidates who
            are ready to contribute, ensuring a perfect match every time.
          </p>
        </div>
        <div className="journey-card">
          <FontAwesomeIcon icon={faRocket} className="card-icon" />
          <h3>Targeted Connections</h3>
          <p>
            We focus on aligning students with startups based on shared goals,
            values, and skills, creating impactful collaborations.
          </p>
        </div>
        <div className="journey-card">
          <FontAwesomeIcon icon={faLock} className="card-icon" />
          <h3>Streamlined Engagement</h3>
          <p>
            No postings, no applications - just direct connections between
            students and startups, making the hiring process smoother and
            faster.
          </p>
        </div>
      </div>
    </section>
  );
};

