// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.job-section {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 60px 20px;
  background: linear-gradient(to right, #d7e8f6, #ebf4fb, #d7e8f6);
  /* background: linear-gradient(90deg, rgba(180, 199, 255, 1) 0%, rgba(230, 240, 255, 1) 100%); */
  width: 100%;
}

.job-container {
  max-width: 1000px;
  text-align: center;
  padding: 40px;
  background-color: #f5f8fc;
  border-radius: 25px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.job-title {
  font-size: 36px;
  font-weight: 700;
  color: #172042;
  margin-bottom: 20px;
  font-family: "Arial", sans-serif;
}

.job-description {
  font-size: 18px;
  color: #4c576d;
  line-height: 1.6;
  font-family: "Arial", sans-serif;
}

@media (max-width: 992px) {
  .job-container {
    margin: auto 30px;
  }

  .job-title {
    font-size: 30px;
  }
  .job-description {
    font-size: 16px;
  }
}

@media (max-width: 768px) {
  .job-container {
    padding: 20px;
    margin: auto 30px;
  }
  .job-title {
    font-size: 24px;
  }
  .job-description {
    font-size: 14px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/HomePage/ProblemPoster/ProblemPoster.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;EAClB,gEAAgE;EAChE,gGAAgG;EAChG,WAAW;AACb;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,aAAa;EACb,yBAAyB;EACzB,mBAAmB;EACnB,yCAAyC;AAC3C;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,cAAc;EACd,mBAAmB;EACnB,gCAAgC;AAClC;;AAEA;EACE,eAAe;EACf,cAAc;EACd,gBAAgB;EAChB,gCAAgC;AAClC;;AAEA;EACE;IACE,iBAAiB;EACnB;;EAEA;IACE,eAAe;EACjB;EACA;IACE,eAAe;EACjB;AACF;;AAEA;EACE;IACE,aAAa;IACb,iBAAiB;EACnB;EACA;IACE,eAAe;EACjB;EACA;IACE,eAAe;EACjB;AACF","sourcesContent":[".job-section {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding: 60px 20px;\n  background: linear-gradient(to right, #d7e8f6, #ebf4fb, #d7e8f6);\n  /* background: linear-gradient(90deg, rgba(180, 199, 255, 1) 0%, rgba(230, 240, 255, 1) 100%); */\n  width: 100%;\n}\n\n.job-container {\n  max-width: 1000px;\n  text-align: center;\n  padding: 40px;\n  background-color: #f5f8fc;\n  border-radius: 25px;\n  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);\n}\n\n.job-title {\n  font-size: 36px;\n  font-weight: 700;\n  color: #172042;\n  margin-bottom: 20px;\n  font-family: \"Arial\", sans-serif;\n}\n\n.job-description {\n  font-size: 18px;\n  color: #4c576d;\n  line-height: 1.6;\n  font-family: \"Arial\", sans-serif;\n}\n\n@media (max-width: 992px) {\n  .job-container {\n    margin: auto 30px;\n  }\n\n  .job-title {\n    font-size: 30px;\n  }\n  .job-description {\n    font-size: 16px;\n  }\n}\n\n@media (max-width: 768px) {\n  .job-container {\n    padding: 20px;\n    margin: auto 30px;\n  }\n  .job-title {\n    font-size: 24px;\n  }\n  .job-description {\n    font-size: 14px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
