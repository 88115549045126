import React, { useEffect, useRef, useState } from "react";
import {
  FaArrowRight,
  FaUserCircle,
  FaHandshake,
  FaEnvelopeOpenText,
  FaCheckCircle,
} from "react-icons/fa";
import "./WorkFlow.css";

export default function WorkFlow() {
  const sectionRef = useRef(null);
  const [showButton, setShowButton] = useState(false); 

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("in-view");

            setTimeout(() => {
              setShowButton(true);
            }, 4000);
          }
        });
      },
      {
        threshold: 0.1, 
      }
    );

    const steps = document.querySelectorAll(".workflow-step, .arrow-icon");
    steps.forEach((step) => observer.observe(step));

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <section className="workflow-section" ref={sectionRef}>
      <div className="workflow-container">
        <h1 className="workflow-title">How It Works</h1>
        <div className="workflow-steps-horizontal">
          <div className="workflow-step">
            <div className="step-content">
              <FaUserCircle className="step-icon" />
              <h2 className="step-title">Create Your Profile</h2>
              <p className="step-description">
                Share your background, skills, and interests. Let startups know
                what you’re passionate about and what you’re looking for in an
                internship.
              </p>
            </div>
          </div>
          <FaArrowRight className="arrow-icon" />
          <div className="workflow-step">
            <div className="step-content">
              <FaHandshake className="step-icon" />
              <h2 className="step-title">Get Matched</h2>
              <p className="step-description">
                Our platform uses advanced matching technology to align your
                profile with startups that fit your skills, values, and career
                goals.
              </p>
            </div>
          </div>
          <FaArrowRight className="arrow-icon" />
          <div className="workflow-step">
            <div className="step-content">
              <FaEnvelopeOpenText className="step-icon" />
              <h2 className="step-title">Receive Interview Invites</h2>
              <p className="step-description">
                When a startup sees you as a great fit, they’ll send you an
                interview invite directly. No need to apply or search through
                job postings.
              </p>
            </div>
          </div>
          <FaArrowRight className="arrow-icon" />
          <div className="workflow-step">
            <div className="step-content">
              <FaCheckCircle className="step-icon" />
              <h2 className="step-title">Choose Your Path</h2>
              <p className="step-description">
                Review your interview invites, connect with the startups that
                interest you, and decide which opportunities are right for you.
              </p>
            </div>
          </div>
        </div>

        {/* Create Profile Button - Visible after 4 seconds */}
        <div
          className={`create-profile-btn-container ${showButton ? "show" : ""}`}
        >
          <button className="create-profile-btn">Create Profile</button>
        </div>
      </div>
    </section>
  );
}
