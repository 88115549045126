import React, { useEffect } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "./StudentReview.css";

gsap.registerPlugin(ScrollTrigger);

export default function StudentReview () {
  useEffect(() => {
    const heading = document.querySelector(".feedback-heading");
    const feedbackCards = document.querySelectorAll(".feedback-card");
    gsap.set(feedbackCards, {
      opacity: 0,
      y: 100,
    });

    gsap.set(heading, {
      opacity: 1,
    });
    gsap
      .timeline({
        scrollTrigger: {
          trigger: heading,
          start: "top center",
          end: "bottom center",
          scrub: true,
        },
      })
      .to(heading, {
        opacity: 0,
        duration: 1,
      });

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: feedbackCards[0],
        start: "top 90%",
        end: "+=400",
        scrub: true,
      },
    });

    feedbackCards.forEach((card, index) => {
      tl.to(
        card,
        {
          opacity: 1,
          y: 0,
          duration: 2,
          ease: "power2.out",
        },
        `+=${index * 0.5}`
      );
    });
  }, []);

  return (
    <section className="feedback-section">
      <h2 className="feedback-heading">What Our Interns Say</h2>
      <div className="feedback-card-container">
        <div className="feedback-card">
          <i className="fas fa-quote-left review-quote-icon"></i>
          <p>
            It makes so much sense that startups find us first. It was nearly
            impossible to connect with them because they never post any
            openings. This platform is perfect-I just created my profile, and
            suddenly so many startups were reaching out. It’s amazing to be
            noticed without having to search endlessly!
          </p>
          <p className="feedback-reviewer-name">Alex T., Data Analyst Intern</p>
        </div>

        <div className="feedback-card">
          <i className="fas fa-quote-left review-quote-icon"></i>
          <p>
            Working at a startup through this platform was a game-changer. It
            inspired me to pursue my own entrepreneurial dreams after graduation
            instead of going down the corporate path. I’m now planning to launch
            my own startup thanks to the hands-on experience I gained.
          </p>
          <p className="feedback-reviewer-name">
            Emily W., Product Management Intern
          </p>
        </div>

        <div className="feedback-card">
          <i className="fas fa-quote-left review-quote-icon"></i>
          <p>
            I can’t believe how much time I used to waste applying to
            internships one by one. After creating my profile here, I got
            interview invites from three different companies in less than a
            month. It’s the most efficient way to find the right opportunities!
          </p>
          <p className="feedback-reviewer-name">James L., Marketing Intern</p>
        </div>
      </div>
    </section>
  );
};

