// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.solution-poster {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 80px 20px;
  background-color: #f5f8fc;
  width: 100%;
  text-align: center;
}

.poster-container {
  max-width: 1500px;
  padding: 40px;
  text-align: center;
}

.poster-title {
  font-size: 70px;
  font-weight: 500;
  color: #172042;
  margin-bottom: 20px;
  font-family: "Arial", sans-serif;
}

.poster-description {
  max-width: 1050px;
  font-size: 26px;
  font-weight: 300;
  color: #172042;
  text-align: center;
  margin: auto;
}

@media (max-width: 992px) {
  .poster-description {
    max-width: 750px;
  }

  .poster-title {
    font-size: 36px;
  }

  .poster-description {
    font-size: 18px;
  }
}

@media (max-width: 768px) {
  .poster-container {
    padding: 20px;
  }

  .poster-title {
    font-size: 28px;
  }

  .poster-description {
    max-width: 550px;
    font-size: 16px;
  }
}

@media (max-width: 556px) {
  .poster-container {
    padding: 10px;
  }

  .poster-title {
    font-size: 24px;
  }

  .poster-description {
    max-width: 450px;
    font-size: 14px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/HomePage/SolutionPoster/SolutionPoster.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;EAClB,yBAAyB;EACzB,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;EACjB,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,cAAc;EACd,mBAAmB;EACnB,gCAAgC;AAClC;;AAEA;EACE,iBAAiB;EACjB,eAAe;EACf,gBAAgB;EAChB,cAAc;EACd,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE;IACE,gBAAgB;EAClB;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,eAAe;EACjB;AACF;;AAEA;EACE;IACE,aAAa;EACf;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,gBAAgB;IAChB,eAAe;EACjB;AACF;;AAEA;EACE;IACE,aAAa;EACf;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,gBAAgB;IAChB,eAAe;EACjB;AACF","sourcesContent":[".solution-poster {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  padding: 80px 20px;\n  background-color: #f5f8fc;\n  width: 100%;\n  text-align: center;\n}\n\n.poster-container {\n  max-width: 1500px;\n  padding: 40px;\n  text-align: center;\n}\n\n.poster-title {\n  font-size: 70px;\n  font-weight: 500;\n  color: #172042;\n  margin-bottom: 20px;\n  font-family: \"Arial\", sans-serif;\n}\n\n.poster-description {\n  max-width: 1050px;\n  font-size: 26px;\n  font-weight: 300;\n  color: #172042;\n  text-align: center;\n  margin: auto;\n}\n\n@media (max-width: 992px) {\n  .poster-description {\n    max-width: 750px;\n  }\n\n  .poster-title {\n    font-size: 36px;\n  }\n\n  .poster-description {\n    font-size: 18px;\n  }\n}\n\n@media (max-width: 768px) {\n  .poster-container {\n    padding: 20px;\n  }\n\n  .poster-title {\n    font-size: 28px;\n  }\n\n  .poster-description {\n    max-width: 550px;\n    font-size: 16px;\n  }\n}\n\n@media (max-width: 556px) {\n  .poster-container {\n    padding: 10px;\n  }\n\n  .poster-title {\n    font-size: 24px;\n  }\n\n  .poster-description {\n    max-width: 450px;\n    font-size: 14px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
