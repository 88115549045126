import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import logo from "../../../assets/logo/small_transparent_logo.png";
import "./Header.css";

export default function Header ({ centerLinks, rightLinks }) {
  const handleScroll = (id) => {
    document.getElementById(id).scrollIntoView({ behavior: "smooth" });
  };

  return (
    <Navbar expand="lg" variant="light" className="custom-navbar" fixed="top">
      <Navbar.Brand href="/" className="brand-logo">
        <img src={logo} alt="Logo" className="logo" />
      </Navbar.Brand>

      <Navbar.Toggle
        aria-controls="navbarResponsive"
        className="custom-toggler"
      >
        <FontAwesomeIcon icon={faBars} className="custom-menu-icon" />
      </Navbar.Toggle>

      <Navbar.Collapse id="navbarResponsive">
        <Nav className="mx-auto nav-center">
          {centerLinks.map((link, index) => (
            <Nav.Link key={index} onClick={() => handleScroll(link.id)}>
              {link.label}
            </Nav.Link>
          ))}
        </Nav>

        <Nav className="right-nav">
          {rightLinks.map((link, index) => (
            <a
              href={link.href ? link.href : "#"}
              key={index}
              className={link.className ? link.className : "dynamic-link"}
              onClick={link.onClick ? link.onClick : null}
            >
              {link.label}
            </a>
          ))}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

// Set default props for Header component
Header.defaultProps = {
  centerLinks: [
    { id: "home", label: "Home" },
    { id: "mission", label: "Mission" },
    { id: "faq", label: "FAQ" },
    { id: "about", label: "About Us" },
  ],
  rightLinks: [
    { href: "/company/login", label: "Company", className: "companies-link" },
    { href: "/student/landing", label: "Student", className: "students-link" },
  ],
};

