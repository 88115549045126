import React from "react";
import "./SolutionPoster.css";
import studentImage from "../../../assets/solution_poster/Students.png";
import companyImage from "../../../assets/solution_poster/Companies.png";

import CompanyCard from "./CustomCard/CustomCard";
import { useNavigate } from "react-router-dom";

export default function SolutionPoster() {
  const navigate = useNavigate();

  const handleBookDemoClick = () => {
    window.open(
      "https://calendly.com/mariagorskikh/vylero-demo-for-startups?month=2024-10",
      "_blank"
    );
  };

  return (
    <section className="solution-poster">
      <div className="poster-container">
        <h1 className="poster-title">
          Personalized Connections for Meaningful Impact
        </h1>
        <p className="poster-description">
          Vylero is transforming the way individuals and startups connect. We
          believe in a more personal approach, one that aligns your unique
          talents and values with opportunities that make a difference.
        </p>
      </div>
      <CompanyCard
        image={studentImage}
        content={{
          title: "Students",
          description:
            "Create a single, dynamic profile that showcases who you are - your skills, passions, and what you stand for.",
        }}
        buttonTitle1="Create Your Profile"
        onButtonClick1={() => navigate("new/student/login")}
        flexDirection="row"
      />

      <CompanyCard
        image={companyImage}
        content={{
          title: "Companies",
          description:
            "Define your ideal candidate and let our AI match you with individuals who not only fit the role but also your mission.",
        }}
        buttonTitle1="Join Our Network"
        onButtonClick1={() => navigate("/company/login")}
        buttonTitle2="Book Demo"
        onButtonClick2={handleBookDemoClick}
        flexDirection="row-reverse"
      />
    </section>
  );
}
