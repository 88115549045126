import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useProfile } from "../../../Contex/ProfileContext";
import "./AdditionalInformation.css";

export default function AdditionalInformation({
  handlePreviousStep,
  handleNextStep,
}) {
  const { profileData, updateProfileData, targetedIndustriesList } =
    useProfile();

  const [errors, setErrors] = useState({});
  const [showRelocationQuestion, setShowRelocationQuestion] = useState(false);

  useEffect(() => {
    if (!profileData.internshipSearchStatus) {
      updateProfileData({ internshipSearchStatus: "Active" });
    }

    const hasHybridOrInPerson = profileData.preferredWorkLocation?.some(
      (location) => location === "Hybrid" || location === "In-Person"
    );
    setShowRelocationQuestion(hasHybridOrInPerson);
  }, [
    profileData.preferredWorkLocation,
    profileData.internshipSearchStatus,
    updateProfileData,
  ]);

  const customSelectStyles = {
    control: (provided, state) => ({
      ...provided,
      border: "1px solid #d7e8f6",
      borderRadius: "8px",
      padding: "0px 3px",
      boxShadow: "none",
      "&:hover": {
        border: "1px solid #f68712",
      },
      borderColor: state.isFocused ? "#f68712" : "#d7e8f6",
    }),
    input: (provided) => ({
      ...provided,
      padding: "0px 10px",
      fontSize: "14px",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#f68712"
        : state.isFocused
        ? "#f7e8d6"
        : "white",
      color: state.isSelected ? "white" : "#132540",
      padding: "6px 15px",
      cursor: "pointer",
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: "#f7e8d6",
      borderRadius: "8px",
      padding: "2px 5px",
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: "#132540",
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: "#f68712",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#f68712",
        color: "white",
      },
    }),
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    updateProfileData({ [name]: value });
  };

  const validate = () => {
    const newErrors = {};
    if (
      !profileData.preferredWorkLocation ||
      profileData.preferredWorkLocation.length === 0
    ) {
      newErrors.preferredWorkLocation =
        "Please select at least one work location.";
    }

    if (showRelocationQuestion && !profileData.openToRelocation) {
      newErrors.openToRelocation =
        "Please select if you are open to relocating.";
    }

    if (
      !profileData.targetedIndustries ||
      profileData.targetedIndustries.length === 0
    ) {
      newErrors.targetedIndustries =
        "Please select at least one targeted industry.";
    }

    if (!profileData.internshipSearchStatus) {
      newErrors.internshipSearchStatus =
        "Please select your current internship search status.";
    }

    return newErrors;
  };

  const onNext = () => {
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setErrors({});
      handleNextStep();
    }
  };

  const handleTargetedIndustriesChange = (selectedOptions) => {
    const selectedValues = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    if (selectedValues.includes("ALL")) {
      // If "ALL" is selected, select all industries
      updateProfileData({
        targetedIndustries: targetedIndustriesList.map((industry) => industry),
      });
    } else {
      updateProfileData({
        targetedIndustries: selectedValues,
      });
    }
  };

  return (
    <div className="additional-info-wrapper">
      <h3 className="additional-info-header">Additional Information</h3>

      {/* Work Location Preferences */}
      <div className="additionInfo-formGroup">
        <label
          htmlFor="preferredWorkLocation"
          className="additional-info-label"
        >
          Preferred Work Location
        </label>
        <Select
          isMulti
          name="preferredWorkLocation"
          options={[
            { value: "Remote", label: "Remote" },
            { value: "In-Person", label: "In-Person" },
            { value: "Hybrid", label: "Hybrid" },
          ]}
          value={profileData.preferredWorkLocation?.map((location) => ({
            value: location,
            label: location,
          }))}
          onChange={(selectedOptions) =>
            updateProfileData({
              preferredWorkLocation: selectedOptions.map(
                (option) => option.value
              ),
            })
          }
          placeholder="Select Work Locations..."
          classNamePrefix="additional-info-select"
          styles={customSelectStyles}
        />
        {errors.preferredWorkLocation && (
          <span className="additional-info-error">
            {errors.preferredWorkLocation}
          </span>
        )}
      </div>

      {/* Open to Relocating */}
      {showRelocationQuestion && (
        <div className="additionInfo-formGroup">
          <label htmlFor="openToRelocation" className="additional-info-label">
            Are you open to relocating?
          </label>
          <Select
            name="openToRelocation"
            options={[
              { value: "Yes", label: "Yes" },
              { value: "No", label: "No" },
              { value: "Not Sure", label: "Not Sure" },
            ]}
            value={
              profileData.openToRelocation
                ? {
                    value: profileData.openToRelocation,
                    label: profileData.openToRelocation,
                  }
                : null
            }
            onChange={(selectedOption) =>
              updateProfileData({ openToRelocation: selectedOption.value })
            }
            placeholder="Select Relocation Option..."
            classNamePrefix="additional-info-select"
            styles={customSelectStyles}
          />
          {errors.openToRelocation && (
            <span className="additional-info-error">
              {errors.openToRelocation}
            </span>
          )}
        </div>
      )}

      {/* Targeted Industries */}
      <div className="additionInfo-formGroup">
        <label htmlFor="targetedIndustries" className="additional-info-label">
          Targeted Industries
        </label>
        <Select
          isMulti
          name="targetedIndustries"
          options={[
            { value: "ALL", label: "ALL" },
            ...targetedIndustriesList.map((industry) => ({
              value: industry,
              label: industry,
            })),
          ]}
          value={profileData.targetedIndustries?.map((industry) => ({
            value: industry,
            label: industry,
          }))}
          onChange={handleTargetedIndustriesChange}
          placeholder="Select Targeted Industries..."
          classNamePrefix="additional-info-select"
          styles={customSelectStyles}
        />
        {errors.targetedIndustries && (
          <span className="additional-info-error">
            {errors.targetedIndustries}
          </span>
        )}
      </div>

      {/* Internship Search Status */}
      <div className="additionInfo-formGroup">
        <label className="additional-info-label">
          Current Internship Search Status
        </label>
        <div className="custom-radio-group">
          <div
            className={`custom-radio-button ${
              profileData.internshipSearchStatus === "Active" ? "selected" : ""
            }`}
            onClick={() => updateProfileData({ internshipSearchStatus: "Active" })}
          >
            <div className="custom-radio-circle"></div>
            <div className="custom-radio-text">
              <strong>Active:</strong> I’m actively looking for internships and
              want to receive interview invites and updates.
            </div>
          </div>

          <div
            className={`custom-radio-button ${
              profileData.internshipSearchStatus === "Passive" ? "selected" : ""
            }`}
            onClick={() => updateProfileData({ internshipSearchStatus: "Passive" })}
          >
            <div className="custom-radio-circle"></div>
            <div className="custom-radio-text">
              <strong>Future:</strong> I’ll be looking for internships later,
              but not right now.
              <br />
              <span className="note">
                (Note: Remember to log back in and update your status when
                you’re ready to receive invites.)
              </span>
            </div>
          </div>
        </div>
        {errors.internshipSearchStatus && (
          <span className="additional-info-error">
            {errors.internshipSearchStatus}
          </span>
        )}
      </div>

      {/* Navigation Buttons */}
      <div className="additional-info-buttons">
        <button
          type="button"
          onClick={handlePreviousStep}
          className="unique-prev-btn"
        >
          Previous
        </button>
        <button type="button" onClick={onNext} className="unique-next-btn">
          Next
        </button>
      </div>
    </div>
  );
}
