import React, { useState } from "react";
import Select from "react-select";
import "./RoleIndustryPreferences.css";
import { useProfile } from "../../../Contex/ProfileContext";
import roleCategoriesData from "../../../utils/StudentProfileData/roleCategories.json";

export default function RoleIndustryPreferences({
  handlePreviousStep,
  handleNextStep,
}) {
  const { profileData, updateProfileData, internshipPeriodsList } =
    useProfile();

  const [errors, setErrors] = useState({});

  const customSelectStyles = {
    control: (provided, state) => ({
      ...provided,
      border: "1px solid #d7e8f6",
      borderRadius: "8px",
      padding: "0px 3px",
      boxShadow: "none",
      "&:hover": {
        border: "1px solid #f68712",
      },
      borderColor: state.isFocused ? "#f68712" : "#d7e8f6",
    }),
    input: (provided) => ({
      ...provided,
      padding: "0px 10px",
      fontSize: "14px",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#f68712"
        : state.isFocused
        ? "#f7e8d6"
        : "white",
      color: state.isSelected ? "white" : "#132540",
      padding: "6px 15px",
      cursor: "pointer",
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: "#f7e8d6",
      borderRadius: "8px",
      padding: "2px 5px",
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: "#132540",
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: "#f68712",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#f68712",
        color: "white",
      },
    }),
  };

  const handleCategoryChange = (selectedOptions) => {
    const selectedCategories = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];

    const validSubcategories = getSubCategories(selectedCategories);

    const filteredSubcategories = profileData.roleSubCategory.filter(
      (subCategory) =>
        validSubcategories.some((sub) => sub.value === subCategory)
    );

    updateProfileData({
      ...profileData,
      roleCategories: selectedCategories,
      roleSubCategory: filteredSubcategories,
    });
  };

  const getSubCategories = (categories = []) => {
    return categories.flatMap((category) => {
      const selectedCategory = roleCategoriesData.roleCategories.find(
        (cat) => cat.category === category
      );
      return selectedCategory ? selectedCategory.subCategories : [];
    });
  };

  const handleSubCategoryChange = (selectedOptions) => {
    const selectedSubCategories = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    if (selectedSubCategories.length <= 3) {
      updateProfileData({
        ...profileData,
        roleSubCategory: selectedSubCategories,
      });
    }
  };

  const handleInternshipPeriodChange = (selectedOptions) => {
    const selectedPeriods = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
  
    updateProfileData({
      ...profileData,
      internshipAvailability: selectedPeriods,
      periodPreferences: selectedPeriods.reduce((acc, period) => {
        acc[period] = profileData.periodPreferences?.[period] || {
          partTime: false,
          fullTime: false,
          unpaid: false,
        };
        return acc;
      }, {}),
    });
  };
  
  const handleCheckboxChange = (e, period, preferenceType) => {
    updateProfileData({
      ...profileData,
      periodPreferences: {
        ...profileData.periodPreferences,
        [period]: {
          ...profileData.periodPreferences[period],
          [preferenceType]: e.target.checked,
        },
      },
    });
  };
  



  const validate = () => {
    const newErrors = {};

    if (
      !profileData.roleCategories ||
      profileData.roleCategories.length === 0
    ) {
      newErrors.roleCategories = "Please select at least one domain.";
    }

    if (
      !profileData.roleSubCategory ||
      profileData.roleSubCategory.length === 0
    ) {
      newErrors.roleSubCategory = "Please select at least one role.";
    }

    if (
      !profileData.internshipAvailability ||
      profileData.internshipAvailability.length === 0
    ) {
      newErrors.internshipAvailability =
        "Please select at least one internship period.";
    }

    const periodErrors = profileData.internshipAvailability.reduce((acc, period) => {
      const preferences = profileData.periodPreferences[period] || {};
      if (
        !preferences.partTime &&
        !preferences.fullTime &&
        !preferences.unpaid
      ) {
        acc[period] =
          "At least one option (Part-Time, Full-Time, Unpaid) must be selected.";
      }
      return acc;
    }, {});

    if (Object.keys(periodErrors).length > 0) {
      newErrors.periodPreferences = periodErrors;
    }

    return newErrors;
  };

  const onNext = () => {
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setErrors({});
      handleNextStep();
    }
  };

  return (
    <div className="refined-role-industry-form">
      <h3 className="refined-section-header">
        Role and Industry Preferences & Availability
      </h3>

      <div className="roll-formGroup">
        <label htmlFor="roleCategory" className="refined-field-label">
          Select Domain
        </label>
        <Select
          isMulti
          name="roleCategory"
          options={roleCategoriesData.roleCategories.map((category) => ({
            value: category.category,
            label: category.category,
          }))}
          value={profileData.roleCategories?.map((category) => ({
            value: category,
            label: category,
          }))}
          onChange={handleCategoryChange}
          placeholder="Select Industries..."
          classNamePrefix="refined-custom-select"
          styles={customSelectStyles}
        />
        {errors.roleCategories && (
          <span className="refined-error-message">{errors.roleCategories}</span>
        )}
      </div>

      <div className="roll-formGroup">
        <label htmlFor="roleSubCategory" className="refined-field-label">
          Select Roles (Max 3)
        </label>
        <Select
          isMulti
          name="roleSubCategory"
          options={getSubCategories(profileData.roleCategories)}
          value={profileData.roleSubCategory?.map((subCategory) => ({
            value: subCategory,
            label: subCategory,
          }))}
          onChange={handleSubCategoryChange}
          placeholder="Select Sub-Categories (Max 3)..."
          classNamePrefix="refined-custom-select"
          styles={customSelectStyles}
        />
        {errors.roleSubCategory && (
          <span className="refined-error-message">
            {errors.roleSubCategory}
          </span>
        )}
      </div>

      <div className="roll-formGroup">
        <label className="refined-field-label">
          Select all times you’re open for internships
        </label>
        <Select
          isMulti
          name="internshipPeriods"
          options={internshipPeriodsList}
          value={profileData.internshipAvailability?.map((period) => ({
            value: period,
            label: period,
          }))}
          onChange={handleInternshipPeriodChange}
          placeholder="Select Internship Periods..."
          classNamePrefix="refined-custom-select"
          styles={customSelectStyles}
        />
        {errors.internshipAvailability && (
          <span className="refined-error-message">
            {errors.internshipAvailability}
          </span>
        )}

        {profileData.internshipAvailability?.length > 0 && (
          <table className="refined-preferences-table">
            <thead>
              <tr>
                <th>Period</th>
                <th>Part-Time</th>
                <th>Full-Time</th>
                <th>Open to Unpaid?</th>
              </tr>
            </thead>
            <tbody>
              {profileData.internshipAvailability.map((period, index) => (
                <tr key={index}>
                  <td>{period}</td>
                  <td>
                    <input
                      type="checkbox"
                      checked={
                        profileData.periodPreferences?.[period]?.partTime ||
                        false
                      }
                      onChange={(e) =>
                        handleCheckboxChange(e, period, "partTime")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      checked={
                        profileData.periodPreferences?.[period]?.fullTime ||
                        false
                      }
                      onChange={(e) =>
                        handleCheckboxChange(e, period, "fullTime")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      checked={
                        profileData.periodPreferences?.[period]?.unpaid || false
                      }
                      onChange={(e) =>
                        handleCheckboxChange(e, period, "unpaid")
                      }
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}

        {errors.periodPreferences && (
          <div className="refined-error-message table-error">
            {Object.entries(errors.periodPreferences).map(([period, error]) => (
              <p key={period}>
                <strong>{period}:</strong> {error}
              </p>
            ))}
          </div>
        )}
      </div>

      <div className="refined-navigation-buttons">
        <button
          type="button"
          onClick={handlePreviousStep}
          className="unique-prev-btn"
        >
          Previous
        </button>
        <button type="button" onClick={onNext} className="unique-next-btn">
          Next
        </button>
      </div>
    </div>
  );
}
