import { useEffect, useState } from "react";
import Select from "react-select";
import { useProfile } from "../../../Contex/ProfileContext";
import "./BasicInformationForm.css";

export default function BasicInformationForm({
  handleNextStep,
  graduationYears = [],
  degreePrograms = [],
  staticMajors = [],
}) {
  const {
    profileData,
    updateProfileData,
    universities,
    fetchUniversities,
    states,
  } = useProfile();
  const [errors, setErrors] = useState({});
  const firstName = localStorage.getItem("firstName");
  const lastName = localStorage.getItem("lastName");
  const email = localStorage.getItem("email");
   const phoneNumber = localStorage.getItem("phoneNumber");

  // Fetch user details from localStorage
  useEffect(() => {

    if (universities.length === 0) {
      fetchUniversities();
    }
  }, [universities, fetchUniversities]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    updateProfileData({ [name]: value });
  };

  const customSelectStyles = {
    control: (provided, state) => ({
      ...provided,
      border: "1px solid #d7e8f6",
      borderRadius: "8px",
      padding: "0px 3px",
      boxShadow: "none",
      "&:hover": {
        border: "1px solid #f68712",
      },
      borderColor: state.isFocused ? "#f68712" : "#d7e8f6",
    }),
    input: (provided) => ({
      ...provided,
      padding: "0px 10px",
      fontSize: "14px",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#f68712"
        : state.isFocused
        ? "#f7e8d6"
        : "white",
      color: state.isSelected ? "white" : "#132540",
      padding: "6px 15px",
      cursor: "pointer",
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: "#f7e8d6",
      borderRadius: "8px",
      padding: "2px 5px",
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: "#132540",
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: "#f68712",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#f68712",
        color: "white",
      },
    }),
  };

  // Validation function
  const validate = () => {
    const newErrors = {};
    if (!profileData.university)
      newErrors.university = "University is required.";
    if (!profileData.graduationYear)
      newErrors.graduationYear = "Graduation Year is required.";
    if (!profileData.degreeProgram)
      newErrors.degreeProgram = "Degree Program is required.";
    if (!profileData.major) newErrors.major = "Major is required.";
    if (!profileData.stateOfResidence)
      newErrors.stateOfResidence = "State of Residence is required.";
    if (
      !profileData.summarySentence ||
      profileData.summarySentence.length < 150 ||
      profileData.summarySentence.length > 250
    ) {
      newErrors.summarySentence =
        "Summary must be between 150 and 250 characters.";
    }
    return newErrors;
  };

  const onNext = () => {
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setErrors({});
      handleNextStep();
    }
  };

  return (
    <div className="basicInfoForm">
      <h3 className="sectionHeader">Basic Information</h3>

      {/* First and Last Name Fields */}
      <div className="formRow">
        <div className="formGroup">
          <label htmlFor="firstName" className="formLabel">
            First Name
          </label>
          <input
            type="text"
            name="firstName"
            placeholder="Enter first name"
            value={firstName}
            readOnly
            className="basicInfoInputField"
          />
        </div>
        <div className="formGroup">
          <label htmlFor="lastName" className="formLabel">
            Last Name
          </label>
          <input
            type="text"
            name="lastName"
            placeholder="Enter last name"
            value={lastName}
            readOnly
            className="basicInfoInputField"
          />
        </div>
      </div>

      {/* Email and Phone Number Fields */}
      <div className="formRow">
        <div className="formGroup">
          <label htmlFor="email" className="formLabel">
            Email
          </label>
          <input
            type="email"
            name="email"
            placeholder="Enter email"
            value={email}
            readOnly
            className="basicInfoInputField"
          />
        </div>
        <div className="formGroup">
          <label htmlFor="phoneNumber" className="formLabel">
            Phone Number
          </label>
          <input
            type="tel"
            name="phoneNumber"
            placeholder="Enter phone number"
            value={phoneNumber}
            readOnly
            className="basicInfoInputField"
          />
        </div>
      </div>

      {/* University and Graduation Year Fields */}
      <div className="formRow">
        <div className="formGroup">
          <label htmlFor="university" className="formLabel">
            University
          </label>
          <Select
            name="university"
            options={universities.map((university) => ({
              value: university,
              label: university,
            }))}
            value={
              profileData.university
                ? {
                    value: profileData.university,
                    label: profileData.university,
                  }
                : null
            }
            onChange={(selectedOption) =>
              updateProfileData({ university: selectedOption.value })
            }
            placeholder="Select University"
            classNamePrefix="react-select"
            styles={customSelectStyles}
          />
          {errors.university && (
            <p className="errorText">{errors.university}</p>
          )}
        </div>
        <div className="formGroup">
          <label htmlFor="graduationYear" className="formLabel">
            Graduation Year
          </label>
          <Select
            name="graduationYear"
            options={graduationYears.map((year) => ({
              value: year,
              label: year,
            }))}
            value={
              profileData.graduationYear
                ? {
                    value: profileData.graduationYear,
                    label: profileData.graduationYear,
                  }
                : null
            }
            onChange={(selectedOption) =>
              updateProfileData({ graduationYear: selectedOption.value })
            }
            placeholder="Select Graduation Year"
            classNamePrefix="react-select"
            styles={customSelectStyles}
          />
          {errors.graduationYear && (
            <p className="errorText">{errors.graduationYear}</p>
          )}
        </div>
      </div>

      {/* Degree Program and Major Fields */}
      <div className="formRow">
        <div className="formGroup">
          <label htmlFor="degreeProgram" className="formLabel">
            Degree Program
          </label>
          <Select
            name="degreeProgram"
            options={degreePrograms.map((program) => ({
              value: program,
              label: program,
            }))}
            value={
              profileData.degreeProgram
                ? {
                    value: profileData.degreeProgram,
                    label: profileData.degreeProgram,
                  }
                : null
            }
            onChange={(selectedOption) =>
              updateProfileData({ degreeProgram: selectedOption.value })
            }
            placeholder="Select Degree Program"
            classNamePrefix="react-select"
            styles={customSelectStyles}
          />
          {errors.degreeProgram && (
            <p className="errorText">{errors.degreeProgram}</p>
          )}
        </div>
        <div className="formGroup">
          <label htmlFor="major" className="formLabel">
            Major
          </label>
          <Select
            name="major"
            options={staticMajors.map((major) => ({
              value: major,
              label: major,
            }))}
            value={
              profileData.major
                ? { value: profileData.major, label: profileData.major }
                : null
            }
            onChange={(selectedOption) =>
              updateProfileData({ major: selectedOption.value })
            }
            placeholder="Select Major"
            classNamePrefix="react-select"
            styles={customSelectStyles}
          />
          {errors.major && <p className="errorText">{errors.major}</p>}
        </div>
      </div>

      {/* State of Residence Field */}
      <div className="formRow">
        <div className="formGroup">
          <label htmlFor="stateOfResidence" className="formLabel">
            State of Residence
          </label>
          <Select
            name="stateOfResidence"
            options={states.map((state) => ({
              value: state.name,
              label: state.name,
            }))}
            value={
              profileData.stateOfResidence
                ? {
                    value: profileData.stateOfResidence,
                    label: profileData.stateOfResidence,
                  }
                : null
            }
            onChange={(selectedOption) =>
              updateProfileData({ stateOfResidence: selectedOption.value })
            }
            placeholder="Select State"
            classNamePrefix="react-select"
            styles={customSelectStyles}
          />
          {errors.stateOfResidence && (
            <p className="errorText">{errors.stateOfResidence}</p>
          )}
        </div>
      </div>

      {/* Bio with Character Counter */}
      <div className="formRow">
        <div className="formGroup">
          <label htmlFor="summarySentence" className="formLabel">
            Bio
          </label>
          <textarea
            id="summarySentence"
            name="summarySentence"
            placeholder="Brief Introduction (Min 150, Max 250 characters)"
            value={profileData.summarySentence || ""}
            onChange={handleChange}
            maxLength={250}
            className="textareaField"
          />
          <p
            className={`characterCounter ${
              profileData.summarySentence.length > 250 ||
              profileData.summarySentence.length < 150
                ? "overLimit"
                : ""
            }`}
          >
            {profileData.summarySentence?.length || 0}/250 characters
          </p>
          {errors.summarySentence && (
            <p className="errorText">{errors.summarySentence}</p>
          )}
        </div>
      </div>

      {/* Navigation Button */}
      <div className="buttonContainer">
        <button type="button" onClick={onNext} className="btn">
          Next
        </button>
      </div>
    </div>
  );
}
