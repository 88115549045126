import React from "react";
import { useNavigate } from "react-router-dom";
import "./HeroSection.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBriefcase,
  faUserGraduate,
  faSchool,
  faLightbulb,
  faHeartbeat,
  faDollarSign,
  faPaintBrush,
  faGlobe,
  faCogs,
} from "@fortawesome/free-solid-svg-icons";
import demoImage from "../../../assets/front_gif/test1.png";

export default function HeroSection() {
  const navigate = useNavigate();

  const handleStudentClick = () => {
    navigate("/student/landing");
  };

  const handleCompanyClick = () => {
    navigate("/company/login");
  };

  
  return (
    <section className="tagline">
      <div className="tagline-main-content">
        <div className="tagline-content">
          <div className="tagline-content-left">
            <div className="tagline-content-left-labels">
              <div className="tagline-label">
                <FontAwesomeIcon
                  icon={faBriefcase}
                  className="icon startups-icon"
                />
                Startups
              </div>
              <div className="tagline-label">
                <FontAwesomeIcon
                  icon={faUserGraduate}
                  className="icon students-icon"
                />
                Students
              </div>
              <div className="tagline-label">
                <FontAwesomeIcon
                  icon={faSchool}
                  className="icon schools-icon"
                />
                Schools & Universities
              </div>
            </div>
            <div className="tagline-content-left-headers">
              <h5 className="massage">Connecting Ambition with Opportunity</h5>
              <h1 className="title">
                The Talent Platform for Startup Innovators
              </h1>
            </div>
            <div className="tagline-content-left-buttons">
              <button className="company-btn" onClick={handleCompanyClick}>
                Companies <span className="arrow">→</span>
              </button>
              <button className="student-btn" onClick={handleStudentClick}>
                Students <span className="arrow">→</span>
              </button>
            </div>
          </div>
          <div className="tagline-content-right">
            <div className="tagline-content-right-pic">
              <img src={demoImage} alt="Demo" className="right-image" />
            </div>
            <div className="tagline-content-right-buttons">
              <div className="tagline-content-right-buttons-content">
                <FontAwesomeIcon icon={faLightbulb} className="icon" />
                <h3>Technology & Innovation</h3>
              </div>
              <div className="tagline-content-right-buttons-content">
                <FontAwesomeIcon icon={faHeartbeat} className="icon" />
                <h3>Health & Life Sciences</h3>
              </div>
              <div className="tagline-content-right-buttons-content">
                <FontAwesomeIcon icon={faDollarSign} className="icon" />
                <h3>Business & Strategy</h3>
              </div>
              <div className="tagline-content-right-buttons-content">
                <FontAwesomeIcon icon={faPaintBrush} className="icon" />
                <h3>Creative & Marketing</h3>
              </div>
              <div className="tagline-content-right-buttons-content">
                <FontAwesomeIcon icon={faGlobe} className="icon" />
                <h3>Impact & Sustainability</h3>
              </div>
              <div className="tagline-content-right-buttons-content">
                <FontAwesomeIcon icon={faCogs} className="icon" />
                <h3>Data & Analytics</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

