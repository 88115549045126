import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/General/Header/navigateBackHeader";
import "./FormPage.css";

export default function FormPage() {
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState({
    availability: "",
    location: "",
    compensation: "",
    availabilityType: "",
    interview_link: "",
    position: "",
    job_description: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const validate = () => {
    let tempErrors = {};
    if (!formValues.job_description) {
      tempErrors.job_description = "Job description is required";
    } 
    else if (formValues.job_description.length < 200) {
      tempErrors.job_description =
        "Job description must be at least 200 characters";
    }
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const token = localStorage.getItem("authToken");
  console.log("Token being used:", token);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validate()) return;
    setIsLoading(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/search/evaluate`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(formValues),
        }
      );

      const data = await response.json();
      console.log("Data from API:", data); // Debugging line
      if (response.ok) {
        console.log({ token });
        navigate("/candidate", {
          state: { candidates: data.best_candidates, formValues },
        });
      } else {
        setErrors({
          api:
            data.message || "An error occurred while processing your request.",
        });
      }
    } catch (error) {
      setErrors({ api: "A network error occurred. Please try again later." });
    } finally {
      setIsLoading(false);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("authToken"); // Remove token from localStorage
    console.log("Token removed:", token);
    navigate("/login"); // Redirect to login page
  };

  return (
    <div>
      <Header />
      <div className="form-page">
        <div className="form-container">
          <h2>Find Your Best Intern</h2>
          {errors.api && <p className="error-message">{errors.api}</p>}
          <form onSubmit={handleSubmit}>
            <label>Availability</label>
            <select
              name="availability"
              value={formValues.availability}
              onChange={handleChange}
            >
              <option value="Summer 2024">Summer 2024</option>
              <option value="Fall 2024">Fall 2024</option>
              <option value="Spring 2025">Spring 2025</option>
            </select>
            <label>Location</label>
            <select
              name="location"
              value={formValues.location}
              onChange={handleChange}
            >
              <option value="Remote">Remote</option>
              <option value="Onsite">Onsite</option>
              <option value="Both">Both</option>
              <option value="Anything Preferred">Anything Preferred</option>
            </select>
            <label>Preferred Compensation</label>
            <select
              name="compensation"
              value={formValues.compensation}
              onChange={handleChange}
            >
              <option value="Paid">Paid</option>
              <option value="Unpaid">Unpaid</option>
              <option value="Anything Preferred">Anything Preferred</option>
            </select>
            <label>Availability Type</label>
            <input
              type="text"
              name="availabilityType"
              placeholder="e.g., Full time, Part time"
              value={formValues.availabilityType}
              onChange={handleChange}
            />
            <label>Interview Link</label>
            <input
              type="text"
              name="interview_link"
              placeholder="e.g. www.calendly.com/a12345"
              value={formValues.interview_link}
              onChange={handleChange}
            />
            <label>Position</label>
            <input
              type="text"
              name="position"
              placeholder="e.g., Software Engineer Intern"
              value={formValues.position}
              onChange={handleChange}
            />
            <label>Job Description</label>
            <textarea
              name="job_description"
              placeholder="Describe the responsibilities and requirements for this position"
              value={formValues.job_description}
              onChange={handleChange}
              rows={4}
            />
            {errors.job_description && (
              <p className="error-message">{errors.job_description}</p>
            )}
            <button type="submit" className="form-button" disabled={isLoading}>
              {isLoading ? "Loading..." : "Search top candidates"}
            </button>
          </form>
          <button className="logout-button-form" onClick={handleLogout}>
            Logout
          </button>
        </div>
      </div>
    </div>
  );
}

