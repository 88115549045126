import React from "react";
import Header from "../../components/General/Header/Header";
import HeroSection from "../../components/HomePage/HeroSection/HeroSection";
import MiddleRoler from "../../components/HomePage/MiddleRoler/MiddleRoler";
import ProblemPoster from "../../components/HomePage/ProblemPoster/ProblemPoster";
import SolutionPoster from "../../components/HomePage/SolutionPoster/SolutionPoster";
import Mission from "../../components/HomePage/Mission/Mission";
import Reason from "../../components/HomePage/Reason/Reason";
import Review from "../../components/HomePage/Review/Review";
import FAQSection from "../../components/HomePage/FAQ/FAQSection";
import Footer from "../../components/General/Footer/Footer";

export default function HomePage() {
  return (
    <div style={{ paddingTop: "70px" }} id="home">
      <Header />
      <HeroSection />
      <MiddleRoler />
      <ProblemPoster />
      <SolutionPoster />
      <Mission />
      <Reason />
      {/* <Review /> */}
      <FAQSection />
      <Footer />
    </div>
  );
}

