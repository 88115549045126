import React, { useState, useContext, useEffect } from "react";
import PhoneInput from "react-phone-number-input";
import { isValidPhoneNumber } from "libphonenumber-js";
import { useNavigate } from "react-router-dom";
import "react-phone-number-input/style.css";
import "./StudentSignUp.css";
import axios from "axios";
import ErrorModal from "../../components/General/ErrorModal/ErrorModal";
import Header from "../../components/General/Header/Header";
import { AuthContext } from "../../Contex/AuthContext";
import ClipLoader from "react-spinners/ClipLoader";

export default function StudentSignUp() {
  const { isAuthenticated } = useContext(AuthContext);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    password: "",
    confirmPassword: "",
    verificationMethod: "email",
  });
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const centerLinks = [];
  const rightLinks = [
    {
      href: "/new/student/login",
      label: "Log In",
      className: "companies-link",
    },
    {
      href: "/new/student/signUp",
      label: "Sign Up",
      className: "students-link",
    },
  ];

  useEffect(() => {
    const isProfileComplete = localStorage.getItem("isProfileComplete") === "true";

    if (localStorage.getItem("token") && isAuthenticated) {
      if (!isProfileComplete) {
        navigate("/student/profile");
      } else {
        navigate("/student/dashboard");
      }
    }
  }, [isAuthenticated, navigate]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handlePhoneChange = (phoneNumber) => {
    setFormData({ ...formData, phoneNumber: phoneNumber });
  };

  const handleCloseModal = () => {
    setShowModal(false);
    if (isSuccess) {
      navigate("/new/student/login");
    }
  };

  const validate = () => {
    let tempErrors = [];
    if (!formData.firstName) tempErrors.push("First name is required");
    if (!formData.lastName) tempErrors.push("Last name is required");
    if (!formData.email) {
      tempErrors.push("Email is required");
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      tempErrors.push("Email is not valid");
    }

    if (!formData.password) {
      tempErrors.push("Password is required");
    } else if (formData.password.length < 12) {
      tempErrors.push("Password must be at least 12 characters long");
    } else if (
      !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
        formData.password
      )
    ) {
      tempErrors.push(
        "Password must contain at least 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character"
      );
    }

    if (!formData.confirmPassword)
      tempErrors.push("Confirm password is required");
    else if (formData.password !== formData.confirmPassword)
      tempErrors.push("Passwords do not match");

    if (!formData.phoneNumber) tempErrors.push("Phone number is required");
    else if (!isValidPhoneNumber(formData.phoneNumber))
      tempErrors.push("Phone number is invalid for the selected country");

    setErrors(tempErrors);
    return tempErrors.length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validate()) {
      setIsLoading(true);
      try {
        const {
          firstName,
          lastName,
          email,
          phoneNumber,
          password,
          verificationMethod,
        } = formData;
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/api/candidates/auth/register`,
          {
            firstName,
            lastName,
            email,
            phoneNumber,
            password,
            verificationMethod,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        setMessage(
          "You have successfully signed up. Please verify your email."
        );
        setIsSuccess(true);
        setShowModal(true);
      } catch (error) {
        setErrors([
          error.response?.data?.message ||
            "An error occurred during registration.",
        ]);
        setIsSuccess(false);
        setShowModal(true);
      } finally {
        setIsLoading(false);
      }
    } else {
      setShowModal(true);
    }
  };

  return (
    <div className="signup-page-body">
      <Header centerLinks={centerLinks} rightLinks={rightLinks} />
      <div className="login-page">
        <div className="login-content">
          <div className="login-left">
            <h2>Create Your Account</h2>
            <p>Start exploring exclusive opportunities with startups.</p>
          </div>
          <div className="login-right">
            <form onSubmit={handleSubmit} className="login-form">
              <div className="form-group">
                <label>First Name</label>
                <input
                  type="text"
                  value={formData.firstName}
                  onChange={handleChange}
                  name="firstName"
                  placeholder="Enter your first name"
                  className="name-class"
                  required
                />
              </div>
              <div className="form-group">
                <label>Last Name</label>
                <input
                  type="text"
                  value={formData.lastName}
                  onChange={handleChange}
                  name="lastName"
                  placeholder="Enter your last name"
                  className="name-class"
                  required
                />
              </div>
              <div className="form-group">
                <label>Email</label>
                <input
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  name="email"
                  placeholder="Enter your email"
                  required
                />
              </div>
              <div className="form-group">
                <label>Password</label>
                <input
                  type="password"
                  value={formData.password}
                  onChange={handleChange}
                  name="password"
                  placeholder="Create a password"
                  required
                />
              </div>
              <div className="form-group">
                <label>Confirm Password</label>
                <input
                  type="password"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  name="confirmPassword"
                  placeholder="Confirm your password"
                  required
                />
              </div>
              <div className="form-group">
                <label>Phone Number</label>
                <PhoneInput
                  international
                  defaultCountry="US"
                  value={formData.phoneNumber}
                  onChange={handlePhoneChange}
                  name="phone"
                />
              </div>
              <button type="submit" className="btn-login">
                Create Account
              </button>
              <p className="signup-text">
                Already have an account? <a href="/new/student/login">Log In</a>
              </p>
            </form>
          </div>
        </div>
      </div>
      
      {/* Overlay with loading spinner */}
      {isLoading && (
        <div className="loading-overlay">
          <ClipLoader size={50} color={"#123abc"} loading={isLoading} />
          <p className="loading-text">Loading... Please wait.</p>
        </div>
      )}

      <ErrorModal
        show={showModal}
        title={isSuccess ? "Success" : "Error"}
        errors={isSuccess ? [message] : errors}
        onClose={handleCloseModal}
      />
    </div>
  );
}
