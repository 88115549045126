import React, { useState, useEffect } from "react";
import Select from "react-select";
import "./SkillsAndExperienceForm.css";
import { useProfile } from "../../../Contex/ProfileContext";

export default function SkillsAndExperienceForm({
  handlePreviousStep,
  handleNextStep,
}) {
  const {
    profileData,
    updateProfileData,
    skillsList = [],
    languagesList = [],
    additionalLinks = [],
    setAdditionalLinks,
    resumeError,
    setResumeError,
  } = useProfile();

  const [customSkills, setCustomSkills] = useState([]);
  const [customLanguages, setCustomLanguages] = useState([]);
  const [showCustomSkillInput, setShowCustomSkillInput] = useState(false);
  const [showCustomLanguageInput, setShowCustomLanguageInput] = useState(false);
  const [errors, setErrors] = useState({});
  const [addLinkEnabled, setAddLinkEnabled] = useState(true);
  const [linkError, setLinkError] = useState("");

  const linkedInPattern = /^(https?:\/\/)?(www\.)?linkedin\.com\/.*$/;
  const urlPattern =
    /^(https?:\/\/)?([\w\d\-]+\.){1,3}[a-z]{2,63}(:\d{1,5})?(\/.*)?$/i;

  const customSelectStyles = {
    control: (provided, state) => ({
      ...provided,
      border: "1px solid #d7e8f6",
      borderRadius: "8px",
      padding: "0px 3px",
      boxShadow: "none",
      "&:hover": {
        border: "1px solid #f68712",
      },
      borderColor: state.isFocused ? "#f68712" : "#d7e8f6",
    }),
    input: (provided) => ({
      ...provided,
      padding: "0px 10px",
      fontSize: "14px",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#f68712"
        : state.isFocused
        ? "#f7e8d6"
        : "white",
      color: state.isSelected ? "white" : "#132540",
      padding: "6px 15px",
      cursor: "pointer",
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: "#f7e8d6",
      borderRadius: "8px",
      padding: "2px 5px",
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: "#132540",
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: "#f68712",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#f68712",
        color: "white",
      },
    }),
  };

  const handleLanguageChange = (selectedOptions) => {
    const selectedLanguages = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    updateProfileData({
      languagesSpoken: [...selectedLanguages, ...customLanguages],
    });
    setShowCustomLanguageInput(selectedLanguages.includes("Other"));
  };

  const handleSkillChange = (selectedOptions) => {
    const selectedSkills = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    updateProfileData({ keySkills: [...selectedSkills, ...customSkills] });
    setShowCustomSkillInput(selectedSkills.includes("Other"));
  };

  const handleAddCustomLanguage = (e) => {
    const language = e.target.value;
    if (language && !customLanguages.includes(language)) {
      setCustomLanguages([...customLanguages, language]);
      updateProfileData({
        languagesSpoken: [...profileData.languagesSpoken, language],
      });
      e.target.value = "";
    }
  };

  const handleAddCustomSkill = (e) => {
    const skill = e.target.value;
    if (skill && !customSkills.includes(skill)) {
      setCustomSkills([...customSkills, skill]);
      updateProfileData({ keySkills: [...profileData.keySkills, skill] });
      e.target.value = "";
    }
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file && file.type !== "application/pdf") {
      setResumeError("Only PDF format is allowed for the resume.");
    } else if (file) {
      setResumeError("");
      updateProfileData({ resume: file });
    } else {
      setResumeError("Resume is required.");
    }
  };

  const handleAdditionalLinkChange = (index, value) => {
    const newLinks = [...additionalLinks];
    newLinks[index] = value;
    setAdditionalLinks(newLinks);

    // Validate the last link and enable/disable "Add Link" button
    if (value && urlPattern.test(value)) {
      setAddLinkEnabled(true);
      setLinkError("");
    } else {
      setAddLinkEnabled(false);
      setLinkError("Please enter a valid URL before adding another link.");
    }
  };

  const addAdditionalLink = () => {
    setAdditionalLinks([...additionalLinks, ""]);
    setAddLinkEnabled(false); // Disable until a valid new link is added
  };

  const removeAdditionalLink = (index) => {
    const newLinks = additionalLinks.filter((_, i) => i !== index);
    setAdditionalLinks(newLinks);

    // Reset link error and button enable state after removing the last link
    setAddLinkEnabled(true);
    setLinkError("");
  };

  const validate = () => {
    const newErrors = {};

    if (
      !profileData.languagesSpoken ||
      profileData.languagesSpoken.length === 0
    ) {
      newErrors.languagesSpoken = "At least one language is required.";
    }

    if (!profileData.keySkills || profileData.keySkills.length === 0) {
      newErrors.keySkills = "At least one skill is required.";
    }

    if (!profileData.linkedInProfile) {
      newErrors.linkedInProfile = "LinkedIn profile is required.";
    } else if (!linkedInPattern.test(profileData.linkedInProfile)) {
      newErrors.linkedInProfile = "Please enter a valid LinkedIn URL.";
    }

    if (!profileData.resume) {
      newErrors.resume = "Resume is required.";
    } else if (resumeError) {
      newErrors.resume = resumeError;
    }

    additionalLinks.forEach((link, index) => {
      if (link && !urlPattern.test(link)) {
        newErrors[`additionalLink${index}`] = `Link ${
          index + 1
        } is not a valid URL.`;
      }
    });

    return newErrors;
  };

  const onNext = () => {
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setErrors({});
      handleNextStep();
    }
  };

  return (
    <div className="unique-skills-form">
      <h3 className="unique-section-header">
        Skills and Experience & Portfolio
      </h3>

      <div className="skill-formGroup">
        <label htmlFor="languagesSpoken" className="unique-field-label">
          Languages Spoken
        </label>
        <Select
          id="languagesSpoken"
          name="languagesSpoken"
          isMulti
          options={languagesList.map((language) => ({
            value: language,
            label: language,
          }))}
          value={profileData.languagesSpoken.map((language) => ({
            value: language,
            label: language,
          }))}
          onChange={handleLanguageChange}
          placeholder="Select Languages..."
          classNamePrefix="unique-custom-select"
          styles={customSelectStyles}
        />
        {errors.languagesSpoken && (
          <span className="unique-error-message">{errors.languagesSpoken}</span>
        )}

        {showCustomLanguageInput && (
          <input
            type="text"
            placeholder="Enter language"
            onKeyDown={(e) =>
              e.key === "Enter" ? handleAddCustomLanguage(e) : null
            }
            className="unique-input-field custom-input"
          />
        )}
      </div>

      <div className="skill-formGroup">
        <label htmlFor="keySkills" className="unique-field-label">
          Key Skills
        </label>
        <Select
          id="keySkills"
          name="keySkills"
          isMulti
          options={skillsList.map((skill) => ({
            value: skill,
            label: skill,
          }))}
          value={profileData.keySkills.map((skill) => ({
            value: skill,
            label: skill,
          }))}
          onChange={handleSkillChange}
          placeholder="Select Key Skills..."
          classNamePrefix="unique-custom-select"
          styles={customSelectStyles}
        />
        {errors.keySkills && (
          <span className="unique-error-message">{errors.keySkills}</span>
        )}

        {showCustomSkillInput && (
          <input
            type="text"
            placeholder="Enter skill"
            onKeyDown={(e) =>
              e.key === "Enter" ? handleAddCustomSkill(e) : null
            }
            className="unique-input-field custom-input"
          />
        )}
      </div>

      <div className="skill-formGroup">
        <label htmlFor="linkedin" className="unique-field-label">
          LinkedIn Profile
        </label>
        <input
          type="url"
          name="linkedin"
          placeholder="https://www.linkedin.com/in/your-profile"
          value={profileData.linkedInProfile || ""}
          onChange={(e) => updateProfileData({ linkedInProfile: e.target.value })}
          className="unique-input-field"
        />
        {errors.linkedInProfile && (
          <span className="unique-error-message">{errors.linkedInProfile}</span>
        )}
      </div>

      <div className="skill-formGroup">
        <label htmlFor="resume" className="unique-field-label">
          Resume (PDF only)
        </label>
        <div className="unique-resume-upload-container">
          <label htmlFor="resumeUpload" className="unique-file-upload-label">
            {/* <i className="fas fa-file-upload unique-resume-icon"></i> */}
            <span>Upload Resume</span>
            <input
              type="file"
              id="resumeUpload"
              name="resume"
              accept=".pdf"
              onChange={handleFileUpload}
              className="unique-file-upload"
            />
          </label>
          {profileData.resume && (
            <span className="unique-file-name">{profileData.resume.name}</span>
          )}
        </div>
        {errors.resume && (
          <span className="unique-error-message">{errors.resume}</span>
        )}
      </div>

      <label className="unique-field-label">
        Additional Links (Website, Portfolio, etc.)
      </label>
      {additionalLinks.map((link, index) => (
        <div key={index} className="unique-additional-link-input">
          <input
            type="url"
            placeholder="Add a link"
            value={link}
            onChange={(e) => handleAdditionalLinkChange(index, e.target.value)}
            className="unique-input-field"
          />
          {errors[`additionalLink${index}`] && (
            <span className="unique-error-message">
              {errors[`additionalLink${index}`]}
            </span>
          )}
          <button
            type="button"
            className="unique-remove-link-btn"
            onClick={() => removeAdditionalLink(index)}
          >
            Remove
          </button>
        </div>
      ))}

      {linkError && (
        <div className="unique-error-message" style={{ marginBottom: "10px" }}>
          {linkError}
        </div>
      )}

      <div className="unique-add-link-btn-container">
        <button
          type="button"
          onClick={addAdditionalLink}
          className="unique-add-link-btn"
          disabled={!addLinkEnabled} // Disable until a valid new link is added
        >
          Add Link
        </button>
      </div>

      <div className="unique-navigation-buttons">
        <button
          type="button"
          onClick={handlePreviousStep}
          className="unique-prev-btn"
        >
          Previous
        </button>
        <button type="button" onClick={onNext} className="unique-next-btn">
          Next
        </button>
      </div>
    </div>
  );
}
