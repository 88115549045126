import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { FaRegClock } from "react-icons/fa";
import Header from "../../components/General/Header/navigateBackHeader";
import Footer from "../../components/General/Footer/Footer";
import "bootstrap/dist/css/bootstrap.min.css";
import signupImage from "../../assets/images/svgsignupImage.svg";
import "./StudentEmailVerificationPage.css";

export default function StudentEmailVerificationPage() {
  const [emailOTP, setEmailOTP] = useState(Array(5).fill(""));
  const [phoneOTP, setPhoneOTP] = useState(Array(5).fill(""));
  const [errors, setErrors] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [resendDisabled, setResendDisabled] = useState(true);
  const [timer, setTimer] = useState(120);

  useEffect(() => {
    if (timer === 0) {
      setResendDisabled(false);
      return;
    }
    const interval = setInterval(() => {
      setTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [timer]);

  const handleChange = (event, index, otpType) => {
    const value = event.target.value;
    if (/^\d?$/.test(value)) {
      if (otpType === "email") {
        const newEmailOTP = [...emailOTP];
        newEmailOTP[index] = value;
        setEmailOTP(newEmailOTP);

        // Move focus to the next input if it's not the last box
        if (value && index < 4) {
          document.getElementById(`emailOTP-${index + 1}`).focus();
        }
      } else if (otpType === "phone") {
        const newPhoneOTP = [...phoneOTP];
        newPhoneOTP[index] = value;
        setPhoneOTP(newPhoneOTP);

        // Move focus to the next input if it's not the last box
        if (value && index < 4) {
          document.getElementById(`phoneOTP-${index + 1}`).focus();
        }
      }
    }
  };

  const handleBackspace = (event, index, otpType) => {
    if (event.key === "Backspace") {
      if (otpType === "email" && index > 0 && !emailOTP[index]) {
        document.getElementById(`emailOTP-${index - 1}`).focus();
      } else if (otpType === "phone" && index > 0 && !phoneOTP[index]) {
        document.getElementById(`phoneOTP-${index - 1}`).focus();
      }
    }
  };

  const validate = () => {
    let tempErrors = {};
    if (emailOTP.includes("") || phoneOTP.includes("")) {
      tempErrors.OTP =
        "Both email and phone OTP are required and should be 5 digits each";
    }

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validate()) {
      console.log("OTP is valid, proceeding...", {
        emailOTP: emailOTP.join(""),
        phoneOTP: phoneOTP.join(""),
      });
      // Handle OTP verification and redirection here
    } else {
      setShowModal(true);
    }
  };

  const handleResendOTP = () => {
    // Logic to resend OTP
    setTimer(5);
    setResendDisabled(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div className="signup-page-body">
      <Header />
      <div className="container-fluid signup-page p-0">
        <div className="row no-gutters min-vh-100">
          <div className="col-md-7 col-12 form-section d-flex flex-column justify-content-center">
            <div className="custom-form-container">
              <h2>Email & Phone Verification</h2>
              <p>Enter the OTP sent to your email and phone</p>
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label>Email OTP*</label>
                  <div className="otp-input-container">
                    {emailOTP.map((digit, index) => (
                      <input
                        key={index}
                        type="text"
                        maxLength="1"
                        className="otp-input"
                        id={`emailOTP-${index}`}
                        value={digit}
                        onChange={(e) => handleChange(e, index, "email")}
                        onKeyDown={(e) => handleBackspace(e, index, "email")}
                      />
                    ))}
                  </div>
                </div>

                <div className="form-group">
                  <label>Phone OTP*</label>
                  <div className="otp-input-container">
                    {phoneOTP.map((digit, index) => (
                      <input
                        key={index}
                        type="text"
                        maxLength="1"
                        className="otp-input"
                        id={`phoneOTP-${index}`}
                        value={digit}
                        onChange={(e) => handleChange(e, index, "phone")}
                        onKeyDown={(e) => handleBackspace(e, index, "phone")}
                      />
                    ))}
                  </div>
                </div>

                <button
                  type="submit"
                  className="btn btn-primary btn-block btn-create"
                >
                  Verify
                </button>

                <div className="resend-section mt-3">
                  <button
                    type="button"
                    className="btn btn-secondary btn-block"
                    onClick={handleResendOTP}
                    disabled={resendDisabled}
                  >
                    {resendDisabled
                      ? `Resend OTP in ${Math.floor(timer / 60)}:${timer % 60}`
                      : "Resend OTP"}
                    {resendDisabled && (
                      <FaRegClock className="ml-4 timer-icon" />
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="col-md-5 d-none d-md-block image-section p-0">
            <img
              src={signupImage}
              alt="Signup Background"
              className="img-fluid"
            />
          </div>
        </div>
      </div>

      {/* Modal for displaying errors */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul>
            {Object.values(errors).map((error, index) => (
              <li key={index} className="text-danger">
                {error}
              </li>
            ))}
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Footer />
    </div>
  );
}
