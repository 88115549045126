import React, { createContext, useContext, useState } from "react";
import axios from "axios";
import statesData from "../utils/StudentProfileData/states.json";
import majorsData from "../utils/StudentProfileData/majors.json";
import languagesData from "../utils/StudentProfileData/languages.json";
import skillsData from "../utils/StudentProfileData/skills.json";
import degreeProgramsData from "../utils/StudentProfileData/degreePrograms.json";
import targetedIndustriesData from "../utils/StudentProfileData/targetedIndustries.json";
import internshipPeriodsData from "../utils/StudentProfileData/internshipPeriods.json";
import { useNavigate } from "react-router-dom";

export const ProfileContext = createContext();

export const ProfileProvider = ({ children }) => {
  const navigate = useNavigate();
  const [profileData, setProfileData] = useState({
    firstName: localStorage.getItem("firstName"),
    lastName: localStorage.getItem("lastName"),
    email: localStorage.getItem("email"),
    phoneNumber: localStorage.getItem("phoneNumber"),
    university: "",
    graduationYear: "",
    degreeProgram: "",
    major: "",
    stateOfResidence: "",
    summarySentence: "",
    keySkills: [],
    languagesSpoken: [],
    resume: null,
    roleCategories: [],
    roleSubCategory: [],
    internshipAvailability: [],
    periodPreferences: {},
    preferredWorkLocation: [],
    targetedIndustries: [],
    internshipSearchStatus: "",
    workAuthorization: "",
    gender: "",
    birthday: "",
    ethnicity: "",
    confirmation: false,
    availableHoursPerWeek: "10-20 hours",
  });

  const [resumeError, setResumeError] = useState("");
  const [universities, setUniversities] = useState([]);
  const [errorMessages, setErrorMessages] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [additionalLinks, setAdditionalLinks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const graduationYears = Array.from(
    { length: 5 },
    (_, i) => new Date().getFullYear() + i + 1
  );
  const degreePrograms = degreeProgramsData.degreePrograms;
  const staticMajors = majorsData.majors;
  const states = statesData.states;
  const skillsList = skillsData.skills;
  const languagesList = languagesData.languages;
  const targetedIndustriesList = targetedIndustriesData.targetedIndustriesList;
  const internshipPeriodsList = internshipPeriodsData.internshipPeriodsList;

  const fetchUniversities = async () => {
    try {
      const response = await axios.get(
        "https://raw.githubusercontent.com/Hipo/university-domains-list/master/world_universities_and_domains.json"
      );
      const universities = response.data.map((uni) => uni.name);
      setUniversities(universities);
    } catch (error) {
      setErrorMessages(["Failed to fetch universities."]);
      setModalIsOpen(true);
    }
  };
  

  const updateProfileData = (newData) => {
    setProfileData((prevData) => ({ ...prevData, ...newData }));
  };

  const submitProfileData = async () => {
    const token = localStorage.getItem("token");
    setIsLoading(true);
    // setErrorMessages(["Loading... Please wait while we update your profile."]);
    // setModalIsOpen(true);

    const formData = new FormData();

    const transformedInternshipAvailability =
      profileData.internshipAvailability.map((period) => ({
        period,
        type: Object.entries(profileData.periodPreferences[period] || {})
          .filter(([, value]) => value)
          .map(([key]) => key),
      }));

    Object.entries(profileData).forEach(([key, value]) => {
      if (key === "roleSubCategory") {
        formData.append("subCategories", JSON.stringify(value));
      } else if (key === "internshipAvailability") {
        formData.append(key, JSON.stringify(transformedInternshipAvailability));
      } else if (key !== "roleCategories" && key !== "periodPreferences") {
        if (Array.isArray(value)) {
          formData.append(key, JSON.stringify(value));
        } else {
          formData.append(key, value);
        }
      }
    });

    // console.log("FormData contains:");
    // for (let [key, value] of formData.entries()) {
    //   console.log(`${key}:`, value);
    // }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/candidates/profile`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setErrorMessages(["Profile created successfully!"]);
      setTimeout(() => {
        localStorage.setItem("isProfileComplete", true);
        setModalIsOpen(false);
        navigate("/student/dashboard");
      }, 2000);
    } catch (error) {
      // console.error("Error updating profile:", error);
      setErrorMessages([
        error.response?.data?.message ||
          "Something is wrong, please try again!!",
      ]);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ProfileContext.Provider
      value={{
        profileData,
        universities,
        graduationYears,
        degreePrograms,
        staticMajors,
        states,
        skillsList,
        languagesList,
        targetedIndustriesList,
        resumeError,
        setResumeError,
        additionalLinks,
        setAdditionalLinks,
        internshipPeriodsList,
        errorMessages,
        modalIsOpen,
        fetchUniversities,
        setModalIsOpen,
        setErrorMessages,
        updateProfileData,
        submitProfileData,
        isLoading,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};

export const useProfile = () => useContext(ProfileContext);
