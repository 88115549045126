import React, { useEffect } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "./Mission.css";

gsap.registerPlugin(ScrollTrigger);

export default function Mission () {
  useEffect(() => {
    const container = document.querySelector(".cards-container");
    const cards = document.querySelectorAll(".empower-card");

    const mm = gsap.matchMedia();

    mm.add(
      {
        isDesktop: "(min-width: 541px)",
      },
      () => {
        gsap.set(cards, {
          opacity: 0,
          scale: 0.5,
          yPercent: 10,
        });

        gsap
          .timeline({
            scrollTrigger: {
              trigger: container,
              start: "top top",
              end: `+=800`,
              scrub: true,
              pin: container,
              snap: 1.5 / (cards.length - 1),
            },
          })
          .to(cards, {
            opacity: 0.9,
            scale: 1,
            yPercent: 0,
            stagger: 0,
          });

        return () => {
          ScrollTrigger.getAll().forEach((st) => st.kill());
        };
      }
    );

    return () => {
      mm.revert();
    };
  }, []);

  return (
    <section className="empower-section" id="mission">
      <div className="empower-content">
        <h1>Empower Dreams, Drive Innovation, Create Social Impact</h1>
        <p>
          At Vylero, our mission is simple: to empower you to achieve your
          career dreams while contributing to something bigger. We believe that
          by connecting the right talent with the right opportunities, we can
          change lives and create a ripple effect of positive impact across
          industries.
        </p>
      </div>
      <div className="cards-container">
        <div className="empower-card">
          <h3>Elevate Careers</h3>
          <p>
            Help individuals find opportunities that ignite their passions and
            accelerate their growth.
          </p>
        </div>
        <div className="empower-card">
          <h3>Fuel Startups</h3>
          <p>
            Provide startups with access to talent that propels their vision and
            amplifies their impact.
          </p>
        </div>
        <div className="empower-card">
          <h3>Change the World</h3>
          <p>
            Focus on high-growth and social impact industries to drive positive
            change together.
          </p>
        </div>
      </div>
    </section>
  );
};

