import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../components/General/Header/navigateBackHeader";
import "./CandidateTable.css";
import S3Singleton from '../../s3';
import "../../aws-exports";
import Mammoth from "mammoth";

export default function CandidateTable() {
  const location = useLocation();
  const navigate = useNavigate(); // Hook for navigation
  const [candidates, setCandidates] = useState(
    location.state?.candidates || []
  );
  const [formValues, setFormValues] = useState(
    location.state?.formValues || {}
  );
  //const [open, setOpen] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [loading, setLoading] = useState(null); // Define the loading state
  //const [pdfUrl, setPdfUrl] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);

  useEffect(() => {
    console.log("Location state:", location.state); // Debugging line
    if (location.state) {
      setCandidates(location.state.candidates || []);
      setFormValues(location.state.formValues || {});
    }
  }, [location.state]);

  const token = localStorage.getItem("authToken");

  const fetchPDF = async (pdfKey, fileType) => {
    try {
      const s3 = await S3Singleton.getInstance();
      const data = await s3.getObject({
        Bucket: 'innovatorsresumebucket',
        Key: pdfKey,
      }).promise();

      const blob = new Blob([data.Body], { type: fileType });
      const url = URL.createObjectURL(blob);

      return url;
    } catch (error) {
      console.error("Error fetching file:", error);
      return null;
    }
  };

  const handleResumeClick = async (fileKey, fileType) => {
    setLoading(selectedCandidate?.candidateId);

    const url = await fetchPDF(fileKey, fileType);
    setLoading(null);

    if (url) {
      if (fileType === 'application/pdf') {
        window.open(url, '_blank');
      } else if (fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
        window.open(url, '_blank'); // Opens the docx file in a new tab
      }
    }
  };


  const handleInviteClick = async (candidateId) => {
    setLoading(candidateId);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/search/select-invite`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ selectedCandidateIds: [candidateId] }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      console.log("Invite sent successfully:", data);

      // Optionally handle success (e.g., show a success message)
    } catch (error) {
      console.error("Error sending invite:", error);
    } finally {
      setLoading(null);
    }
  };

  // const handleClickOpen = async (candidateId) => {
  //   try {
  //     const response = await fe tch(
  //       `${process.env.REACT_APP_API_BASE_URL}api/search/evaluate`,
  //       {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${token}`,
  //         },
  //         body: JSON.stringify({ candidateId, ...formValues }),
  //       }
  //     );

  //     const contentType = response.headers.get("content-type");
  //     if (contentType && contentType.includes("application/json")) {
  //       const data = await response.json();
  //       setSelectedCandidate(data);
  //       setOpen(true);
  //     } else {
  //       console.error("Unexpected response format");
  //     }
  //   } catch (error) {
  //     console.error("Error fetching candidate details:", error);
  //   }
  // };

  // const handleClose = () => {
  //   setOpen(false);
  //   setSelectedCandidate(null);
  // };

  const handleLogout = () => {
    localStorage.removeItem("authToken"); // Remove token from localStorage
    console.log("Token removed:", token);
    navigate("/login"); // Redirect to login page
  };

  const getFileType = (filePath) => {
    const extension = filePath.split('.').pop();
    switch (extension) {
      case 'pdf':
        return 'application/pdf';
      case 'docx':
        return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
      case 'doc':
        return 'application/msword';
      // Add other file types as needed
      default:
        return 'application/octet-stream'; // Fallback MIME type
    }
  };
  

  return (
    <div>
      <Header />
      <div className="candidate-table-wrapper">
        <div className="logout-button-container">
          <button className="logout-button" onClick={handleLogout}>
            Logout
          </button>
        </div>
        <div className="candidate-table">
          <h4 className="table-title">Top 10 Candidates for your Position</h4>
          <div className="table-container">
            <table className="candidates-table">
              <thead>
                <tr>
                  <th className="column-name">Candidate Name</th>
                  <th className="column-percentage-match">Percentage Match</th>
                  <th className="column-summary">Profile Summary</th>
                  <th className="column-view-resume">View Resume</th>
                  <th className="column-send-invite">Send Invite</th>
                </tr>
              </thead>
              <tbody>
                {candidates.map((candidate) => (
                  <tr key={candidate.candidateId}>
                    <td>{candidate.name}</td>
                    <td>{candidate.result?.["JD Match"] || "N/A"}</td>
                    <td>
                      <ul>
                        {candidate.result?.["Profile Summary"] 
                          ? candidate.result["Profile Summary"].split('\n\n').map((point, index) => (
                              <li key={index}>{point.trim().replace(/^- /, '')}</li>
                            ))
                          : "N/A"}
                      </ul>
                    </td>
                    <td>
                      <div className="button-container">
                        <button
                          className="view-resume-button"
                          onClick={() => handleResumeClick(candidate.resume,getFileType(candidate.resume))}
                          disabled={loading === candidate.candidateId}
                        >
                          {loading === candidate.candidateId ? (
                            <div className="spinner"></div>
                          ) : (
                            "View Resume"
                          )}
                        </button>
                      </div>
                    </td>
                    <td>
                      <div className="button-container">
                        <button
                          className="send-invite-button"
                          onClick={() => handleInviteClick(candidate.candidateId)}
                          disabled={loading === candidate.candidateId}
                        >
                          {loading === candidate.candidateId ? (
                            <div className="spinner"></div>
                          ) : (
                            "Send Invite"
                          )}
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

