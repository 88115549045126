import React, { useState } from "react";
import Select from "react-select";
import { useProfile } from "../../../Contex/ProfileContext";
import "./PersonalInformation.css";

export default function PersonalInformation({
  handlePreviousStep,
  handleSubmit,
}) {
  const { profileData, updateProfileData } = useProfile();
  const [errors, setErrors] = useState({});

  const customSelectStyles = {
    control: (provided, state) => ({
      ...provided,
      border: "1px solid #d7e8f6",
      borderRadius: "8px",
      padding: "0px 3px",
      boxShadow: "none",
      "&:hover": {
        border: "1px solid #f68712",
      },
      borderColor: state.isFocused ? "#f68712" : "#d7e8f6",
    }),
    input: (provided) => ({
      ...provided,
      padding: "0px 10px",
      fontSize: "14px",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#f68712"
        : state.isFocused
        ? "#f7e8d6"
        : "white",
      color: state.isSelected ? "white" : "#132540",
      padding: "6px 15px",
      cursor: "pointer",
    }),
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    updateProfileData({
      [name]: type === "checkbox" ? checked : value,
    });
  };

  // Validation function
  const validate = () => {
    const newErrors = {};
    if (!profileData.workAuthorization) {
      newErrors.workAuthorization = "Work Authorization is required.";
    }
    return newErrors;
  };

  // Handle Submit with Validation
  const onSubmit = () => {
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setErrors({});
      handleSubmit();
    }
  };

  return (
    <div className="personal-info-form">
      <h3 className="personal-info-header">Personal Information</h3>

      {/* Work Authorization */}
      <div className="personalInfo-formGroup">
        <label htmlFor="workAuthorization" className="personal-info-label">
          Work Authorization *
        </label>
        <Select
          name="workAuthorization"
          options={[
            { value: "Yes, with CPT/OPT", label: "Yes, with CPT/OPT" },
            {
              value: "Yes, U.S. Citizen/Permanent Resident",
              label: "Yes, U.S. Citizen/Permanent Resident",
            },
            { value: "No, I need sponsorship", label: "No, I need sponsorship" },
          ]}
          value={
            profileData.workAuthorization
              ? {
                  value: profileData.workAuthorization,
                  label: profileData.workAuthorization,
                }
              : null
          }
          onChange={(selectedOption) =>
            updateProfileData({
              workAuthorization: selectedOption ? selectedOption.value : "",
            })
          }
          placeholder="Select Work Authorization"
          isClearable
          classNamePrefix="personal-info-select"
          styles={customSelectStyles}
        />
        {errors.workAuthorization && (
          <p className="personal-info-error">{errors.workAuthorization}</p>
        )}
      </div>

      {/* Demographic Information */}
      <div className="demographic-info-box">
        <p className="personal-info-text">
          At Vylero, we are dedicated to fostering a more inclusive environment
          for both students and companies. To help us understand and support
          diverse hiring practices, we ask students to voluntarily share
          demographic information. Self-identifying is entirely optional, and
          your information will be handled with care. Responses regarding gender
          and ethnicity will not be displayed on your profile.
        </p>
      </div>

      {/* Gender */}
      <div className="personalInfo-formGroup">
        <label htmlFor="gender" className="personal-info-label">
          Gender
        </label>
        <Select
          name="gender"
          options={[
            { value: "Male", label: "Male" },
            { value: "Female", label: "Female" },
            { value: "Non-Binary", label: "Non-Binary" },
            { value: "Prefer not to say", label: "Prefer not to say" },
          ]}
          value={
            profileData.gender
              ? { value: profileData.gender, label: profileData.gender }
              : null
          }
          onChange={(selectedOption) =>
            updateProfileData({
              gender: selectedOption ? selectedOption.value : "",
            })
          }
          placeholder="Select Gender"
          isClearable
          classNamePrefix="personal-info-select"
          styles={customSelectStyles}
        />
      </div>

      {/* Birthday */}
      <div className="personalInfo-formGroup">
        <label htmlFor="birthday" className="personal-info-label">
          Date of Birth
        </label>
        <input
          type="date"
          name="birthday"
          value={profileData.birthday || ""}
          onChange={handleChange}
          placeholder="Enter your birthday"
          className="personal-info-input"
        />
      </div>

      {/* Ethnicity/Race */}
      <div className="personalInfo-formGroup">
        <label htmlFor="ethnicity" className="personal-info-label">
          Ethnicity/Race
        </label>
        <Select
          name="ethnicity"
          options={[
            { value: "Hispanic or Latino", label: "Hispanic or Latino" },
            {
              value: "Black or African American",
              label: "Black or African American",
            },
            { value: "White", label: "White" },
            { value: "Asian", label: "Asian" },
            { value: "Other", label: "Other" },
            { value: "Prefer not to say", label: "Prefer not to say" },
          ]}
          value={
            profileData.ethnicity
              ? { value: profileData.ethnicity, label: profileData.ethnicity }
              : null
          }
          onChange={(selectedOption) =>
            updateProfileData({
              ethnicity: selectedOption ? selectedOption.value : "",
            })
          }
          placeholder="Select Ethnicity"
          isClearable
          classNamePrefix="personal-info-select"
          styles={customSelectStyles}
        />
      </div>

      {/* Confirmation */}
      <div className="personalInfo-formGroup">
        <div className="custom-checkbox">
          <input
            type="checkbox"
            name="confirmation"
            id="confirmation"
            checked={profileData.confirmation}
            onChange={handleChange}
          />
          <label htmlFor="confirmation" className="custom-checkbox-label">
            <span className="checkbox-text">
              Please confirm that you have read and understood the program
              rules. By selecting this option, you give us permission to share
              your application with potential employers and allow us to email
              you with invitations for interviews.
            </span>
          </label>
        </div>
      </div>

      {/* Navigation Buttons */}
      <div className="personal-info-buttons">
        <button
          type="button"
          onClick={handlePreviousStep}
          className="unique-prev-btn"
        >
          Previous
        </button>
        <button
          type="button"
          onClick={onSubmit}
          className="personal-info-next-btn"
          disabled={!profileData.confirmation}
        >
          Submit
        </button>
      </div>
    </div>
  );
}
