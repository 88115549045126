import React, { useState, useEffect, useContext } from "react";
import "./StudentLogIn.css";
import logo from "../../assets/logo/small_transparent_logo.png";
import { FcGoogle } from "react-icons/fc";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ErrorModal from "../../components/General/ErrorModal/ErrorModal";
import Header from "../../components/General/Header/Header";
import { AuthContext } from "../../Contex/AuthContext";
import ClipLoader from "react-spinners/ClipLoader";

export default function StudentLogIn() {
  const { login, isAuthenticated } = useContext(AuthContext);
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const centerLinks = [];

  const rightLinks = [
    {
      href: "/new/student/login",
      label: "Log In",
      className: "companies-link",
    },
    {
      href: "/new/student/signUp",
      label: "Sign Up",
      className: "students-link",
    },
  ];

  useEffect(() => {
    const isProfileComplete = localStorage.getItem("isProfileComplete") === "true";

    if (localStorage.getItem("token") && isAuthenticated) {
      if (!isProfileComplete) {
        navigate("/student/profile");
      } else {
        navigate("/student/dashboard");
      }
    }
  }, [isAuthenticated, navigate]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrors([]);
    setMessage("");

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/candidates/auth/login`,
        formData
      );
      setMessage("Login successful!");
      login(
        response.data.uuid,
        response.data.token,
        response.data.firstName,
        response.data.lastName,
        response.data.phoneNumber,
        response.data.email,
        response.data.role,
        response.data.isProfileComplete
      );
    } catch (error) {
      setErrors([
        error.response?.data?.message || "Invalid login credentials.",
      ]);
      setShowModal(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Header centerLinks={centerLinks} rightLinks={rightLinks} />
      <div className="login-page">
        <div className={`login-content`}>
          <div className="login-left">
            <img src={logo} alt="Vylero Logo" className="login-logo" />
            <h2>Welcome Back, Students!</h2>
            <p>Log in to explore exclusive opportunities with startups.</p>
          </div>

          <div className="login-right">
            <form onSubmit={handleSubmit} className="login-form">
              <div className="login-form-group">
                <label>Email</label>
                <input
                  type="email"
                  name="email"
                  placeholder="Enter your email address"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="login-form-group">
                <label>Password</label>
                <input
                  type="password"
                  name="password"
                  placeholder="Enter your password"
                  value={formData.password}
                  onChange={handleChange}
                  required
                />
              </div>

              <button type="submit" className="btn-login" disabled={loading}>
                {loading ? "Logging In..." : "Log In"}
              </button>

              {message && <p className="success-message">{message}</p>}

              <p className="signup-text">
                Don't have an account? <a href="/new/student/signUp">Sign Up</a>
              </p>
            </form>
          </div>
        </div>
      </div>

      {/* Overlay with loading spinner */}
      {loading && (
        <div className="loading-overlay">
          <ClipLoader size={50} color={"#123abc"} loading={loading} />
          <p className="loading-text">Logging in... Please wait.</p>
        </div>
      )}

      <ErrorModal
        show={showModal}
        title="Error"
        errors={errors}
        onClose={handleCloseModal}
      />
    </div>
  );
}
