import React from "react";
import "./MiddleRoler.css";

import logo1 from "../../../assets/roller_logo/1.png";
import logo2 from "../../../assets/roller_logo/2.png";
import logo3 from "../../../assets/roller_logo/3.png";
import logo4 from "../../../assets/roller_logo/4.png";
import logo5 from "../../../assets/roller_logo/5.png";
import logo6 from "../../../assets/roller_logo/6.png";
import logo7 from "../../../assets/roller_logo/7.png";
import logo8 from "../../../assets/roller_logo/8.png";
import logo9 from "../../../assets/roller_logo/9.png";
import logo10 from "../../../assets/roller_logo/10.png";
import logo11 from "../../../assets/roller_logo/11.png";
import logo12 from "../../../assets/roller_logo/12.png";
import logo13 from "../../../assets/roller_logo/13.png";
import logo14 from "../../../assets/roller_logo/14.png";
import logo15 from "../../../assets/roller_logo/15.png";
import logo16 from "../../../assets/roller_logo/16.png";
import logo17 from "../../../assets/roller_logo/17.png";
import logo18 from "../../../assets/roller_logo/18.png";
import logo19 from "../../../assets/roller_logo/19.png";
import logo20 from "../../../assets/roller_logo/20.png";
import logo21 from "../../../assets/roller_logo/21.png";
import logo22 from "../../../assets/roller_logo/22.png";

const logos = [
  logo1,
  logo2,
  logo3,
  logo4,
  logo5,
  logo6,
  logo7,
  logo8,
  logo9,
  logo10,
  logo11,
  logo12,
  logo13,
  logo14,
  logo15,
  logo16,
  logo17,
  logo18,
  logo19,
  logo20,
  logo21,
  logo22,
];

export default function MiddleRoler() {
  return (
    <section className="trusted-section">
      <div className="trusted-container">
        <div className="trusted-left">
          <h2>Trusted by</h2>
        </div>
        <div className="trusted-right">
          <div className="label-slider">
            <div className="logo-track">
              {logos.map((logo, index) => (
                <div key={index} className="label">
                  <img src={logo} alt={`Logo ${index + 1}`} />
                </div>
              ))}
              {logos.map((logo, index) => (
                <div key={index + logos.length} className="label">
                  <img src={logo} alt={`Logo ${index + 1}`} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

