import React from "react";
import "./CardSection.css";

export default function CardSection() {
  const personalInfo = {
    name: "John Doe",
    email: "john.doe@example.com",
    university: "Example University",
    major: "Computer Science",
    classYear: "Class of 2024",
    location: "San Francisco, CA",
    availability: "Full-Time from June 2024",
    skills: ["JavaScript", "React", "Node.js", "Python", "Data Analysis"],
    preferredRoles: ["Software Engineer", "Web Developer", "Data Scientist"],
    achievements: [
      "Dean's List 2022-2023",
      "Hackathon Winner - Best Use of AI",
      "Published paper in IEEE Conference",
    ],
    coursework: [
      "Advanced Algorithms",
      "Machine Learning",
      "Web Development",
      "Database Systems",
    ],
  };

  return (
    <div className="dashboard-container">
      {/* <h1 className="dashboard-title">Dashboard</h1> */}
      <div className="dashboard-grid">
        {/* Personal Information Card */}
        <div className="dashboard-card">
          <div className="card-header orange-header">
            <h2 className="card-title">Personal Information</h2>
          </div>
          <div className="card-body">
            <p className="info-text">
              <strong>{personalInfo.name}</strong>
              <br />
              {personalInfo.email}
              <br />
              <span role="img" aria-label="university">🎓</span> {personalInfo.university}
              <br />
              <span role="img" aria-label="major">💼</span> {personalInfo.major}
              <br />
              <span role="img" aria-label="class-year">📅</span> {personalInfo.classYear}
              <br />
              <span role="img" aria-label="location">📍</span> {personalInfo.location}
            </p>
          </div>
        </div>

        {/* Skills Card */}
        <div className="dashboard-card">
          <div className="card-header navy-header">
            <h2 className="card-title">Skills</h2>
          </div>
          <div className="card-body">
            <div className="skills-tags">
              {personalInfo.skills.map((skill, index) => (
                <span key={index} className="skill-tag">
                  {skill}
                </span>
              ))}
            </div>
          </div>
        </div>

        {/* Preferred Roles Card */}
        <div className="dashboard-card">
          <div className="card-header orange-header">
            <h2 className="card-title">Preferred Roles</h2>
          </div>
          <div className="card-body">
            <ul className="info-list">
              {personalInfo.preferredRoles.map((role, index) => (
                <li key={index}>{role}</li>
              ))}
            </ul>
          </div>
        </div>

        {/* Availability Card */}
        <div className="dashboard-card">
          <div className="card-header navy-header">
            <h2 className="card-title">Availability</h2>
          </div>
          <div className="card-body">
            <p className="info-text">{personalInfo.availability}</p>
          </div>
        </div>

        {/* Achievements Card */}
        <div className="dashboard-card">
          <div className="card-header orange-header">
            <h2 className="card-title">Achievements</h2>
          </div>
          <div className="card-body">
            <ul className="info-list">
              {personalInfo.achievements.map((achievement, index) => (
                <li key={index}>{achievement}</li>
              ))}
            </ul>
          </div>
        </div>

        {/* Relevant Coursework Card */}
        <div className="dashboard-card">
          <div className="card-header navy-header">
            <h2 className="card-title">Relevant Coursework</h2>
          </div>
          <div className="card-body">
            <ul className="info-list">
              {personalInfo.coursework.map((course, index) => (
                <li key={index}>{course}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
