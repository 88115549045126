import React from "react";
import Footer from "../../components/General/Footer/Footer";
import StudentHeroSection from "../../components/StudentLandingPage/HeroSection/StudentHeroSection";
import IntroductionPoster from "../../components/StudentLandingPage/IntroductionPoster/IntroductionPoster";
import WorkFlow from "../../components/StudentLandingPage/WorkFlow/WorkFlow";
import StudentReview from "../../components/StudentLandingPage/StudentReview/StudentReview";
import Expectation from "../../components/StudentLandingPage/Expectation/Expectation";
import IndustriesSection from "../../components/StudentLandingPage/IndustriesSection/IndustriesSection";
import Header from "../../components/General/Header/Header";

export default function StudentLandingPage() {
  const centerLinks = [
  ];

  const rightLinks = [
    { href: "/new/student/login", label: "Log In", className: "companies-link" },
    { href: "/new/student/signUp", label: "Sign Up", className: "students-link" },
  ];

  return (
    <div style={{ paddingTop: "70px" }} id="home">
      <Header centerLinks={centerLinks} rightLinks={rightLinks} />
      <StudentHeroSection/>
      <IntroductionPoster/>
      <WorkFlow/>    
      {/* <StudentReview />  */}
      <Expectation/>
      <IndustriesSection/>
      <Footer />
    </div>
  );
}

