import React from "react";
import "./ProblemPoster.css";

export default function ProblemPoster() {
  return (
    <section className="job-section">
      <div className="job-container">
        <h1 className="job-title">
          Navigating the Job Market Should Be Personal, Not Overwhelming
        </h1>
        <p className="job-description">
          In today's ever-evolving world, finding opportunities that truly
          resonate with your passions and career aspirations shouldn't feel like
          an endless maze. Talented individuals often get lost in impersonal job
          boards and repetitive applications, while innovative startups struggle
          to find the right people who share their vision—especially when
          operating under the radar.
        </p>
      </div>
    </section>
  );
}

